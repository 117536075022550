import { useToast } from '@chakra-ui/react'
import { JSONValue } from '@twilio/conversations'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { Reply } from '../types'
import useGetConversationQueryBySid from './use-get-conversation-query'

export type Vote = {
  identity: string
  timestamp: number
}

export type PollOption = {
  value: string
  votes: Vote[]
  key: string
}

export type Poll = {
  question: string
  options: PollOption[]
  multipleAnswers: boolean
}

export type PollMessageAttributes = JSONValue & {
  type: 'poll'
  reply?: Reply
  poll: Poll
}

const useSendPollMessageMutation = (options = {}): any => {
  const { sid } = useParams()

  const { data } = useGetConversationQueryBySid(sid)
  const conversation = data?.conversation

  const toast = useToast()

  return useMutation(
    'send-poll-message',
    async ({ poll, reply }: { poll: Poll; reply?: Reply }) => {
      const attributes = reply ? { type: 'poll', poll, reply } : { type: 'poll', poll }

      await conversation?.self
        ?.prepareMessage()
        .setBody('')
        .setAttributes(attributes as PollMessageAttributes)
        .build()
        .send()
    },
    {
      onError(error: any) {
        console.error(error)

        toast({
          title: error?.message || 'Qualcosa è andato storto',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      },
      ...options,
    },
  )
}

export default useSendPollMessageMutation
