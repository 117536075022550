import { Clipboard } from '@capacitor/clipboard'
import { Share } from '@capacitor/share'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AspectRatio,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { ReactComponent as AdmonitionBannerBg } from 'assets/admonition-banner-bg.svg'
import Attendees from 'components/shared/attendees/attendees.component'
import CompleteProfileModal from 'components/shared/complete-profile-modal/complete-profile-modal'
import { Alert, Confirm } from 'components/shared/confirm/confirm'
import { MenuIcon, SbamCoinArrow } from 'components/shared/icons/icons'
import Map from 'components/shared/map/map.component'
import QrCodeSvg from 'components/shared/qr-code/qr-code-svg'
import ScanQRCode from 'components/shared/scan-qr-code/scan-qr-code'
import UserAvatar from 'components/shared/user-avatar/user-avatar'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { refreshUser } from 'features/auth/auth.actions'
import { selectorAuth } from 'features/auth/auth.slice'
import useCurrentUserStatus from 'hooks/use-user-status'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { BsChatLeftFill } from 'react-icons/bs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Api from 'services/api/api.service'
import { Attendance, Event, WarningTypeModel } from 'typings/database.d'
import { isAndroid, isIos } from 'utils/platform'
import { genderMap } from '../../costants/genders'

const EventScreen = () => {
  const toast = useToast()
  const { id } = useParams<{ id: string }>()
  const { user } = useSelector(selectorAuth)
  const navigate = useNavigate()
  const [isDeleteOpen, setDeleteOpen] = useState(false)
  const [isDeleteAttendanceOpen, setDeleteAttendanceOpen] = useState(false)
  const onDeleteClose = () => setDeleteOpen(false)
  const cancelDeleteRef = useRef(null)
  const onDeleteAttendanceClose = () => setDeleteAttendanceOpen(false)
  const cancelDeleteAttendanceRef = useRef(null)
  const [isSocialOpen, setSocialOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const onSocialClose = () => {
    setCopied(false)
    setSocialOpen(false)
  }
  const cancelSocialRef = useRef(null)
  const queryClient = useQueryClient()
  const { isOpen: isQROpen, onOpen: onQROpen, onClose: onQRClose } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isLoading, error, data, refetch, isRefetching } = useQuery<
    any,
    any,
    {
      retry: false
      error: String
      isLoading: boolean
      data: Event
      refetch: () => {}
    }
  >('getEvent' + id, () => Api.event(id as string), {})
  const event = data?.data as Event

  useEffect(() => {
    if (error && typeof error == 'string') {
      toast({
        title: error,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    } else if (error && typeof error?.message == 'string') {
      toast({
        title: error.message,
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [error, toast])

  const createAttendanceMutation = useMutation({
    mutationFn: async () => {
      try {
        const createAttendancePromise = Api.createAttendance(id as string)
        return createAttendancePromise
      } catch (e: any) {
        return toast({
          title: e?.response?.data?.message || 'Error',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    },
    onSuccess: () => {
      refetch()
    },
  })

  const createAttendance = async () => {
    if (!user) {
      await Alert({ title: 'Non registrato', message: `Effettua l'accesso o registrati per partecipare all'evento`, confirmText: 'Continua' })
      navigate('/login')
      return
    }
    if (!(/*(isIos() || isAndroid() || (user?.phoneVerified && user?.phone)) &&*/ user?.avatar)) {
      onOpen()
      return
    }
    if (event.limitations.genderBalanceActive && event.limitations[user.gender + 'Participants'] - event.genderBalanceCounts[user.gender] <= 0) {
      const gender = genderMap.find(g => g[0] === user.gender) || ['', 'Sesso non specificato']
      await Alert({ title: 'Posti finiti', message: `I posti per ${gender[1].toLowerCase()} sono finiti`, confirmText: 'ok' })
      return
    }
    if (
      !(await Confirm({
        title: 'Conferma',
        message: `Conferma la tua partecipazione, in caso non potrai più partecipare, annullala per evitare ammonizioni.`,
      }))
    ) {
      return
    }

    createAttendanceMutation.mutate()
  }

  const deleteEvent = async () => {
    if (isOrganizer) {
      setDeleteOpen(true)
    }
  }
  const deleteAttendance = async () => {
    setDeleteAttendanceOpen(true)
  }

  const confirmDeleteAttendance = async () => {
    if (attendance) {
      await Api.deleteAttendance(event._id, attendance._id, attendance.code)
      await queryClient.invalidateQueries('conversations')
      setDeleteAttendanceOpen(false)
      refetch()
      refreshUser()
    }
  }
  const confirmDeleteEvent = async () => {
    if (isOrganizer && id) {
      try {
        await Api.deleteEvent(id)
        navigate('/', { replace: true })
      } catch (e) {
        await Alert({ title: 'Errore', message: `L'evento potrebbe essere in corso/passato o non essere più disponibile.`, confirmText: 'Ho capito' })
      }
    }
  }

  const shareEvent = async () => {
    const { value: canShare } = await Share.canShare()
    if (!canShare) {
      // toast({
      //   title: "Condivisione non abilitata",
      //   status: 'error',
      //   duration: 2500,
      //   isClosable: true,
      //   position: 'top',
      // })
      setSocialOpen(true)

      return
    }

    try {
      await Share.share({
        dialogTitle: 'Condividi evento sui social',
        title: event.title,
        text: event.description,
        url: `${process.env.REACT_APP_APP_URL}${window.location.pathname}`,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const { currentUserStatus } = useCurrentUserStatus()

  if (isLoading || !event.title) return null

  const isUserWarned = currentUserStatus === WarningTypeModel.Admonition

  const { attendances } = event
  const attendance = attendances && attendances.find((e: Attendance) => e.user._id === user._id)
  const isApproved = !!attendance?.approvedAt
  const isOrganizer = user && event.organizer._id === user._id

  // organizer is not null when attendance if he don't attend
  const organizerAttends = attendances && !attendances.find((e: Attendance) => e.user._id === event.organizer._id)
  const organizerAttendance = organizerAttends && { user: event.organizer }

  return (
    <>
      <AspectRatio ratio={375 / 240} bg={`url('${event.coverUrl}') center`} bgSize="cover" mt="-60px" mx="auto" width="100%" maxWidth="500px">
        <Flex bg="rgba(1,25,54,.5)" h="100%" direction="column" justify="flex-end" p="15px 28px" pt="75px">
          <Flex justify="space-between" h="100%" alignItems="flex-end" width="100%">
            <Flex>
              <Menu>
                <MenuButton color="#FFFFFF" fontWeight="600" fontSize="22px" cursor={isOrganizer ? 'pointer' : undefined}>
                  {event.title}
                  {isOrganizer && moment(event.startDate).isAfter() && <MenuIcon fontSize="30px" color="white" />}
                </MenuButton>
                {isOrganizer && moment(event.startDate).isAfter() && (
                  <MenuList>
                    <MenuItem onClick={() => navigate('edit')}>Modifica evento</MenuItem>
                    <MenuItem onClick={deleteEvent}>Cancella evento</MenuItem>
                  </MenuList>
                )}
              </Menu>
            </Flex>
            <Box color="#FFFFFF">
              <Attendees
                max={event.limitations.maxParticipants}
                list={attendances}
                organizer={event.organizer}
                organizerAttendance={organizerAttendance}
                limitations={event.limitations}
                counts={event.genderBalanceCounts}
                endDate={event.endDate}
              />
            </Box>
          </Flex>
        </Flex>
      </AspectRatio>
      {isUserWarned && (
        <>
          <Flex
            gap={2}
            direction="column"
            color="#FFFFFF"
            bgColor="#FFB003"
            width="100%"
            maxWidth="500px"
            mx="auto"
            p="20px 28px"
            position="relative">
            <p>
              Sei stato ammonito. Ti servono
              <br />
              più saette per partecipare...
            </p>
            <Button
              size="sm"
              bgColor="white"
              color="black"
              w="36"
              _hover={{ bgColor: 'lightgray' }}
              onClick={() => {
                navigate('/monition')
              }}>
              Scopri come fare
            </Button>
            <Box position="absolute" right={8} bottom={0}>
              <AdmonitionBannerBg />
            </Box>
          </Flex>
        </>
      )}
      <Flex direction="column" css={{ gap: '20px' }} width="100%" maxWidth="500px" mx="auto" p="20px 28px">
        <Flex css={{ gap: '30px' }}>
          <Flex direction="column">
            <Text fontSize="10px">Categoria</Text>
            <Text fontSize="14px" fontWeight="600">
              {event.category?.name || <i>Nessuna</i>}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize="10px">Contributo</Text>
            <Text fontSize="14px" fontWeight="600">
              {event.cost}€
            </Text>
          </Flex>
          {event.limitations.maxAge && event.limitations.minAge && (
            <Flex direction="column">
              <Text fontSize="10px">Età consigliata</Text>
              <Text fontSize="14px" fontWeight="600">
                {event.limitations.minAge} - {event.limitations.maxAge}
              </Text>
            </Flex>
          )}
        </Flex>
        {!isOrganizer && attendance && !isApproved && (
          <>
            <Divider />
            <Flex align="center">
              <Box onClick={onQROpen} cursor="pointer" mr="15px" bg="#FF7436" p="3px" borderRadius="5px" color="white">
                <Center>
                  <Box p="2px" bg="white" borderRadius="2px">
                    {/* <QRCode value={JSON.stringify({ attendanceId: attendance._id, attendanceCode: attendance.code })} /> */}
                    <QrCodeSvg size={20} level={'L'} value={JSON.stringify({ attendanceId: attendance._id, attendanceCode: attendance.code })} />
                  </Box>
                  <Text ml="3px">Mostra</Text>
                </Center>
              </Box>
              <Flex direction="column">
                <Text fontSize="12px">Fai scansionare il tuo QR all'organizzatore, per confermare la tua presenza e ottenere saette extra!</Text>
              </Flex>
            </Flex>
            <Modal isOpen={isQROpen} onClose={onQRClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Conferma la tua presenza</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb="40px">
                  <Center>
                    <QrCodeSvg
                      size={Math.min(window.innerWidth, 560) * 0.6}
                      level={'L'}
                      value={JSON.stringify({ attendanceId: attendance._id, attendanceCode: attendance.code })}
                    />
                  </Center>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
        {isOrganizer && (
          <>
            <Divider />
            <Flex align="center">
              <ScanQRCode
                eventId={event._id}
                onApprove={() => {
                  toast({
                    title: 'Partecipazione confermata',
                    status: 'success',
                    duration: 2500,
                    isClosable: true,
                    position: 'top',
                  })
                  refetch()
                }}
              />
              <Flex direction="column">
                <Text fontSize="14px">
                  {event.attendancesCount}/{event.limitations.maxParticipants} all'evento
                </Text>
                <Text fontSize="12px" lineHeight="1.1">
                  Conferma la presenza dei partecipanti e ottieni saette extra!
                </Text>
              </Flex>
            </Flex>
          </>
        )}
        <Divider />
        <Flex justify="space-between" width="100%">
          <Flex alignItems="center">
            <Link to={'/profile/' + event.organizer._id} key={event.organizer._id}>
              <Flex css={{ gap: '14px' }}>
                <Box height="38px" width="38px">
                  <UserAvatar src={event.organizer.avatar} size="full" />
                </Box>
                <Flex direction="column">
                  <Text fontSize="10px">Organizzato da</Text>
                  <Text fontSize="14px" fontWeight="600">
                    {event.organizer.firstName} {/*(parteciperá) */}
                  </Text>
                </Flex>
              </Flex>
            </Link>
          </Flex>
          {/* <Box>
            <IconButton borderRadius="15px" height="36px" width="36px">
              <ChatIcon boxSize="7" />
            </IconButton>
          </Box> */}
        </Flex>
        {event.chatSid && attendance && (
          <Button flex="1" p="5px" onClick={() => navigate(`/event/${event.chatSid}/chat`)}>
            Chat di gruppo
            <Box pl="10px" pt="2px" fontSize={14}>
              <BsChatLeftFill scale={0.5} />
            </Box>
          </Button>
        )}
        <Divider />
        <Text fontSize="14px" style={{ whiteSpace: 'pre-line' }}>
          {event.description}
        </Text>
        <Text
          fontSize="14px"
          dangerouslySetInnerHTML={{ __html: moment(event.startDate).format('<b>D MMMM YYYY</b> [alle] <b>h:mm A z</b>') }}></Text>
        {
          <>
            <Box position="fixed" left="0" bottom="60px" marginBottom="env(safe-area-inset-bottom)" width="100%" mx="auto" zIndex="20">
              <Wrapper px="10px">
                <AlertDialog isOpen={isSocialOpen} leastDestructiveRef={cancelSocialRef} onClose={onSocialClose}>
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Condividi sui social
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        {/* <FacebookShareButton translate url={window.location.href}>
                          <Button mb="10px">Facebook</Button>
                        </FacebookShareButton>
                        <br />
                        <WhatsappShareButton translate url={window.location.href}>
                          <Button mb="10px">Whatsapp</Button>
                        </WhatsappShareButton>
                        <br />
                        <TelegramShareButton translate url={window.location.href}>
                          <Button mb="10px">Telegram</Button>
                        </TelegramShareButton> */}
                        <br />
                        <Button
                          onClick={async () => {
                            await Clipboard.write({
                              url: `${process.env.REACT_APP_APP_URL}${window.location.pathname}`,
                            })
                            setCopied(true)
                          }}
                          mb="10px"
                          variant="outline">
                          Copia link{' '}
                          {copied && (
                            <Text color="gray" fontSize="15px" ml="15px">
                              copiato
                            </Text>
                          )}
                        </Button>
                      </AlertDialogBody>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
                {!isOrganizer && attendance && moment(event.startDate).isAfter() && (
                  <Flex width="100%" mb="50px">
                    <AlertDialog isOpen={isDeleteAttendanceOpen} leastDestructiveRef={cancelDeleteAttendanceRef} onClose={onDeleteAttendanceClose}>
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Annulla partecipazione
                          </AlertDialogHeader>
                          <AlertDialogBody>
                            Non verranno rimborsati gli sbam coin spesi. Sei sicuro di voler annullare la tua partecipazione?
                          </AlertDialogBody>
                          <AlertDialogFooter>
                            <Button bg="white" color="black" ref={cancelDeleteAttendanceRef} onClick={onDeleteAttendanceClose}>
                              Indietro
                            </Button>
                            <Button colorScheme="red" onClick={confirmDeleteAttendance} ml={3}>
                              Annulla partecipazione
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                    <Button bg="white" onClick={() => deleteAttendance()}>
                      <svg id="event_busy_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path id="Tracciato_195" data-name="Tracciato 195" d="M0,0H24V24H0Z" fill="none" />
                        <path
                          id="Tracciato_196"
                          data-name="Tracciato 196"
                          d="M9.31,17l2.44-2.44L14.19,17l1.06-1.06L12.81,13.5l2.44-2.44L14.19,10l-2.44,2.44L9.31,10,8.25,11.06l2.44,2.44L8.25,15.94ZM19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z"
                          transform="translate(0 0)"
                          fill="#ff7436"
                        />
                      </svg>
                    </Button>
                    <Button ml="10px" flex="1" width="100%" variant="solid" onClick={shareEvent}>
                      <Text mr="10px">Condividi</Text>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17">
                        <path
                          id="Icon_material-share"
                          data-name="Icon material-share"
                          d="M17,15.016a2.4,2.4,0,0,0-1.633.657L9.425,12.132a2.417,2.417,0,0,0,0-1.195L15.3,7.429A2.458,2.458,0,0,0,17,8.12a2.527,2.527,0,0,0,2.5-2.56,2.5,2.5,0,1,0-5,0,2.857,2.857,0,0,0,.075.6L8.7,9.665A2.458,2.458,0,0,0,7,8.974a2.561,2.561,0,0,0,0,5.12A2.458,2.458,0,0,0,8.7,13.4l5.933,3.55A2.425,2.425,0,1,0,17,15.016Z"
                          transform="translate(-4.5 -3)"
                          fill="#fff"
                        />
                      </svg>
                    </Button>
                  </Flex>
                )}
                {!attendance && moment(event.endDate).isAfter() && (
                  <Flex width="100%" mb="10px" mx="auto">
                    {!isOrganizer && (
                      <>
                        <Button mr="10px" bg="white" color="#1A202C" onClick={shareEvent}>
                          <Text mr="5px">Condividi</Text>
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17">
                            <path
                              id="Icon_material-share"
                              data-name="Icon material-share"
                              d="M17,15.016a2.4,2.4,0,0,0-1.633.657L9.425,12.132a2.417,2.417,0,0,0,0-1.195L15.3,7.429A2.458,2.458,0,0,0,17,8.12a2.527,2.527,0,0,0,2.5-2.56,2.5,2.5,0,1,0-5,0,2.857,2.857,0,0,0,.075.6L8.7,9.665A2.458,2.458,0,0,0,7,8.974a2.561,2.561,0,0,0,0,5.12A2.458,2.458,0,0,0,8.7,13.4l5.933,3.55A2.425,2.425,0,1,0,17,15.016Z"
                              transform="translate(-4.5 -3)"
                              fill="#1A202C"
                            />
                          </svg>
                        </Button>
                      </>
                    )}
                    <Button position="relative" flex="1" onClick={createAttendance} isDisabled={createAttendanceMutation.isLoading || isRefetching}>
                      <Flex gap={2} align="center">
                        <span>Partecipa</span>
                        {isUserWarned && <small style={{ fontWeight: 300, textDecoration: 'line-through' }}>{event.originalAttendanceCost}</small>}
                        <span>
                          {event.attendanceCost} <SbamCoinArrow />
                        </span>
                      </Flex>
                      {/* {isUserWarned && (
                        <Box position="absolute" right={4} bottom={0}>
                          <AdmonitionBannerBg width={48} height={36} />
                        </Box>
                      )} */}
                    </Button>
                  </Flex>
                )}
                {isOrganizer && moment(event.endDate).isAfter() && (
                  <Flex width="100%" mb="50px">
                    {/* If event hasn't started yet */}
                    {moment(event.startDate).isAfter() && (
                      <>
                        <AlertDialog isOpen={isDeleteOpen} leastDestructiveRef={cancelDeleteRef} onClose={onDeleteClose}>
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Cancella evento
                              </AlertDialogHeader>

                              <AlertDialogBody>Sei sicuro? Questa azione non può essere annullata.</AlertDialogBody>

                              <AlertDialogFooter>
                                <Button bg="white" color="black" ref={cancelDeleteRef} onClick={onDeleteClose}>
                                  Annulla
                                </Button>
                                <Button colorScheme="red" onClick={confirmDeleteEvent} ml={3}>
                                  Cancella evento
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>
                        {/* <Button bg="white" onClick={() => deleteEvent()}>
                          <svg id="event_busy_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Tracciato_195" data-name="Tracciato 195" d="M0,0H24V24H0Z" fill="none" />
                            <path
                              id="Tracciato_196"
                              data-name="Tracciato 196"
                              d="M9.31,17l2.44-2.44L14.19,17l1.06-1.06L12.81,13.5l2.44-2.44L14.19,10l-2.44,2.44L9.31,10,8.25,11.06l2.44,2.44L8.25,15.94ZM19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z"
                              transform="translate(0 0)"
                              fill="#ff7436"
                            />
                          </svg>
                        </Button> */}
                      </>
                    )}
                    <Button ml={0} flex="1" width="100%" onClick={shareEvent}>
                      <Text mr="10px">Condividi</Text>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17">
                        <path
                          id="Icon_material-share"
                          data-name="Icon material-share"
                          d="M17,15.016a2.4,2.4,0,0,0-1.633.657L9.425,12.132a2.417,2.417,0,0,0,0-1.195L15.3,7.429A2.458,2.458,0,0,0,17,8.12a2.527,2.527,0,0,0,2.5-2.56,2.5,2.5,0,1,0-5,0,2.857,2.857,0,0,0,.075.6L8.7,9.665A2.458,2.458,0,0,0,7,8.974a2.561,2.561,0,0,0,0,5.12A2.458,2.458,0,0,0,8.7,13.4l5.933,3.55A2.425,2.425,0,1,0,17,15.016Z"
                          transform="translate(-4.5 -3)"
                          fill="#fff"
                        />
                      </svg>
                    </Button>
                    <AlertDialog isOpen={isSocialOpen} leastDestructiveRef={cancelSocialRef} onClose={onSocialClose}>
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Condividi sui social
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            {/* <FacebookShareButton translate url={window.location.href}>
                              <Button mb="10px">Facebook</Button>
                            </FacebookShareButton>
                            <br />
                            <WhatsappShareButton translate url={window.location.href}>
                              <Button mb="10px">Whatsapp</Button>
                            </WhatsappShareButton>
                            <br />
                            <TelegramShareButton translate url={window.location.href}>
                              <Button mb="10px">Telegram</Button>
                            </TelegramShareButton> */}
                            <br />
                            <Button
                              onClick={async () => {
                                await Clipboard.write({
                                  url: `${process.env.REACT_APP_APP_URL}${window.location.pathname}`,
                                })
                              }}
                              mb="10px"
                              variant="outline">
                              Copia link
                            </Button>
                          </AlertDialogBody>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </Flex>
                )}
              </Wrapper>
            </Box>
          </>
        }
      </Flex>
      <Box position="relative" w="90%" maxW="500px" m="auto">
        <Map isFake={!attendance} center={!attendance ? event.location.fakePosition.coordinates : event.location.realPosition.coordinates}></Map>
        <Wrapper position="absolute" bottom="0" left="0" width="100%" height="100%" bgGradient="linear(to-t, rgba(70,83,98,.75), rgba(70,83,98,0))">
          <Box fontWeight="bold" mt="auto" color="white">
            {attendance && (
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://www.google.com/maps/search/' +
                  event.location.details.address +
                  '/@' +
                  event.location.realPosition.coordinates[0] +
                  ',' +
                  event.location.realPosition.coordinates[1] +
                  'z/'
                }>
                {event.location.details.address}
              </a>
            )}
          </Box>
        </Wrapper>
      </Box>
      {isOrganizer && !attendance && <Box h="55px"></Box>}
      <Box h="110px"></Box>
      <CompleteProfileModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  )
}

export default EventScreen
