import { useMutation } from 'react-query'
import { GoogleGenerativeAI } from '@google/generative-ai'

const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY || '')
const model = genAI.getGenerativeModel({ model: 'gemini-1.5-flash' })

const useSendMessageToGeminiMutation = (options = {}) => {
  return useMutation(
    'send-message-to-gemini',
    async (prompt: string): Promise<string> => {
      const result = await model.generateContent(prompt)
      const response = await result.response
      return response.text()
    },
    {
      ...options,
    },
  )
}

export default useSendMessageToGeminiMutation
