import { Box, Button, CloseButton, Flex, FormControl, FormErrorMessage, Heading, Text, useDisclosure, useToast } from '@chakra-ui/react'
import InputField from 'components/shared/input/input.component'
import SelectField from 'components/shared/select/select.component'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { useAppDispatch } from 'features'
import { refreshUser } from 'features/auth/auth.actions'
import {
  selectorAuth,
  setPreviousUser,
  setCanModifyPhoneNumber,
  setSkipNumberConfirmation,
  setIntroSeen,
  setSkipPreferredCategories,
} from 'features/auth/auth.slice'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Api from 'services/api/api.service'
import { User } from 'typings/database.d'
import resizeFile from 'utils/resize-file'
import { logoutAction } from 'features/auth/auth.actions'

const CompleteProfileScreen = () => {
  const toast = useToast()
  const [avatar] = useState<Blob>()
  const { user, previousUser, firebaseUser } = useSelector(selectorAuth)
  const isProviderApple = firebaseUser?.providerData.some(({ providerId }) => providerId === 'apple.com')
  const appleSignInCache = JSON.parse(localStorage.getItem('apple-signin-cache') || '{}')
  const firstNameApple: string = appleSignInCache.firstName
  const lastNameApple: string = appleSignInCache.lastName

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      avatar: (user.avatar as string) || '',
      firstName: (user.firstName as string) || (isProviderApple ? firstNameApple : false) || '',
      lastName: (user.lastName as string) || (isProviderApple ? lastNameApple : false) || '',
      gender: (user.gender as string) || '',
    },
  })

  useEffect(() => {
    if (user) {
      const currentValues = getValues()
      if (!currentValues.firstName || !currentValues.lastName) {
        setValue('firstName', user.firstName)
        setValue('lastName', user.lastName)
      }
    }
  }, [user])

  const { onClose } = useDisclosure()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  /*useEffect(() => {
    if (profileComplete) {
      handleProfileCompletion()
    }
  }, [profileComplete, navigate])*/

  useEffect(() => {
    if (user._id !== previousUser?._id) {
      dispatch(setPreviousUser(user))
      dispatch(setCanModifyPhoneNumber(false))
      dispatch(setSkipNumberConfirmation(false))
      dispatch(setIntroSeen(false))
      dispatch(setSkipPreferredCategories(false))
    }
  }, [])

  const onSubmit = async (data: User) => {
    setIsLoading(true)
    const body = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      gender: data.gender,
    }
    try {
      const result = await Api.editUser(body)
      if (result && result.status === 200) {
        if (avatar) {
          const body = new FormData()
          body.append('avatar', (await resizeFile(avatar)) as Blob)
          try {
            await Api.editAvatar(body)
            onClose()
            dispatch<any>(refreshUser())
          } catch (e: any) {
            toast({
              title: e.response.data.message || 'Error',
              status: 'error',
              duration: 2500,
              isClosable: true,
              position: 'bottom',
            })
          }
        } else {
          dispatch<any>(refreshUser())
        }
      }
    } catch (e: any) {
      if (e?.response?.data?.message) {
        toast({
          title: e.response.data.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
      if (typeof e?.response?.data === 'string') {
        toast({
          title: e.response.data,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      }
    }
    setIsLoading(false)
  }

  return (
    <Wrapper pt="20px" pb="90px">
      <form style={{ display: 'contents' }} onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="row-reverse" padding="0">
          <CloseButton
            size="lg"
            onClick={() => {
              dispatch<any>(logoutAction())
            }}
          />
        </Flex>
        <Heading mt={5} size="lg" mb={10} fontWeight="bold">
          Parlaci di te
        </Heading>
        <Flex mb="30px">
          <FormControl isInvalid={!!errors.firstName} mr={1}>
            <InputField label="Nome" placeholder="Mario" required {...register('firstName', { required: 'Devi specificare un nome' })} />
            <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.lastName} ml={1}>
            <InputField label="Cognome" placeholder="Rossi" required {...register('lastName', { required: 'Devi specificare un cognome' })} />
            <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Box mb="30px">
          <FormControl isInvalid={!!errors.gender}>
            <Text pb="5px" fontWeight="semibold" color="#465362">
              Sesso
              <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                *
              </Text>
            </Text>
            <SelectField
              backgroundColor="#eeeeee"
              _hover={{ bgColor: '#eeeeee' }}
              defaultValue=""
              borderRadius="15px"
              {...register('gender', { required: 'Devi specificare un sesso', validate: v => v !== '' || 'Devi specificare un sesso' })}>
              <option value={''}>Seleziona</option>
              <option value={'male'}>Uomo</option>
              <option value={'female'}>Donna</option>
              <option value={'other'}>Altro</option>
            </SelectField>
            <FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Button isLoading={isLoading} height="55px" width="100%" alignSelf="center" type="submit">
          Avanti
        </Button>
      </form>
    </Wrapper>
  )
}

export default CompleteProfileScreen
