import { Input, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import styled from '@emotion/styled'

// const ILE = styled(InputLeftElement)`
//   border-radius: 15px;
//   font-size: 14px;
//   height: 100%;
//   width: auto;
//   padding: 15px 20px;
//   display: flex;
//   align-items: center;
// `

export const IRE = styled(InputRightElement)`
  border-radius: 15px;
  font-size: 14px;
  height: 100%;
  display: flex;
  width: unset;
  align-items: center;
  padding: 5px;
  margin-right: 10px;
`
export const ILE = styled(InputLeftElement)`
  border-radius: 15px;
  font-size: 14px;
  height: 100%;
  display: flex;
  width: unset;
  align-items: center;
  padding: 10px;
`

export const I = styled(Input)`
  border-radius: 15px;
  font-size: 14px;
  height: unset;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #eeeeee;
  &:hover {
    background-color: #eeeeee;
  }
`

export const PositionI = styled(Input)`
  border-radius: 15px;
  font-size: 14px;
  height: unset;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 80px;
  background-color: #eeeeee;
  &:hover {
    background-color: #eeeeee;
  }
`

export const LittleI = styled(Input)`
  border-radius: 15px;
  font-size: 14px;
  height: unset;
  padding: 5px 10px;
  background-color: #eeeeee;
  &:hover {
    background-color: #eeeeee;
  }
`

export const CalendarI = styled(Input)`
  &::-webkit-calendar-picker-indicator {
    position: relative;
    background-color: #ff7436;
    left: 11px;
    top: 0;
    margin: 0;
    padding: 0.5rem;
    cursor: pointer;
    color: white;
    border-radius: 10px;
    width: 44px;
    height: 26px;
    background-image: url('data:image/svg+xml,<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.3333 2.41667H18.125V0H15.7083V2.41667H6.04167V0H3.625V2.41667H2.41667C1.07542 2.41667 0.0120835 3.50417 0.0120835 4.83333L0 21.75C0 23.0792 1.07542 24.1667 2.41667 24.1667H19.3333C20.6625 24.1667 21.75 23.0792 21.75 21.75V4.83333C21.75 3.50417 20.6625 2.41667 19.3333 2.41667ZM19.3333 21.75H2.41667V9.66667H19.3333V21.75ZM19.3333 7.25H2.41667V4.83333H19.3333V7.25ZM16.9167 13.2917H10.875V19.3333H16.9167V13.2917Z" fill="white"/></svg>');
    background-position: center;
  }
  border-radius: 15px;
  font-size: 14px;
  height: unset;
  padding: 6px 20px;
  background-color: #eeeeee;
  &:hover {
    background-color: #eeeeee;
  }
`

I.defaultProps = {
  focusBorderColor: '#FF7436',
}
