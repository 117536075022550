import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { Input } from 'components/shared/input/input.component'
import Map from 'components/shared/map/map.component'
import useGetCurrentPositionQuery from 'hooks/use-get-current-position-mutation'
import React, { useRef, useState } from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { Location } from 'typings/database'
import { useChatContext } from '../context/chat-context'
import useSendPositionMessageMutation from '../hooks/use-send-position-message-mutation'

function SharePosition() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleOnClick = async () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <Flex alignItems="center" gap=".75rem" cursor="pointer" onClick={handleOnClick}>
        <Box color="brand.500" fontSize="1.5rem">
          <FaMapMarkerAlt />
        </Box>
        Posizione
      </Flex>

      {isModalOpen && <SharedPositionModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
    </>
  )
}

function SharedPositionModal({ isOpen, setIsModalOpen }: { isOpen: boolean; setIsModalOpen: (boolean) => void }) {
  const coordeRef = useRef<[number, number]>()
  const { setIsChatPlusMenuBoxVisible, setReply, reply, chatRef } = useChatContext()

  const [mapRef, setMapRef] = useState<google.maps.Map>()
  const [coords, setCoords] = useState<[number, number]>()
  const [location, setLocation] = useState<Location>()

  const getCurrentPosition = useGetCurrentPositionQuery({
    onSuccess(data) {
      coordeRef.current = [data.coordinates.coords.longitude, data.coordinates.coords.latitude]
      setCoords([data.coordinates.coords.longitude, data.coordinates.coords.latitude])
      setLocation(data.place.location)
    },
  })

  const handleOnClose = () => {
    setIsModalOpen(false)
  }

  const handleOnChangePosition = value => {
    if (!value.placeId) {
      setLocation(undefined)
    }

    const coords = value?.realPosition?.coordinates

    coordeRef.current = [coords[0], coords[1]]
    setCoords(coords)
    setLocation(value)
  }

  const sendMessage = useSendPositionMessageMutation({
    onSuccess() {
      setIsModalOpen(false)
      setIsChatPlusMenuBoxVisible(false)
      setReply(undefined)

      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current?.scrollHeight
      }
    },
  })

  const handleOnLoad = map => {
    setMapRef(map)
  }

  const handleOnDragEnd = () => {
    if (!mapRef) {
      return
    }

    setCoords([mapRef.getCenter()!.lng(), mapRef.getCenter()!.lat()])
    setLocation(undefined)
  }

  const handleOnSendPosition = async () => {
    if (sendMessage.isLoading) {
      return
    }

    sendMessage.mutate({ coords, reply })
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="40px">
          {getCurrentPosition.isLoading ? (
            <>
              <Spinner size="sm" mr="10px" /> Caricamento posizione corrente
            </>
          ) : (
            <Flex direction="column" gap="1rem" justify="center">
              <Input onChange={handleOnChangePosition} placeholder="Indirizzo" type="location" value={location} />

              {coordeRef.current && (
                <Map
                  isFake={false}
                  onLoad={handleOnLoad}
                  onDragEnd={handleOnDragEnd}
                  center={coordeRef.current}
                  options={{ draggable: true, scrollwheel: true, panControl: true }}
                />
              )}

              {!coordeRef.current && (
                <Alert status="info">
                  <AlertIcon />
                  Seleziona l'indirizzo che vuoi condividere per visualizzarlo nella mappa
                </Alert>
              )}

              <Button
                borderRadius="10px"
                isDisabled={!coordeRef.current || getCurrentPosition.isLoading}
                isLoading={sendMessage.isLoading}
                onClick={handleOnSendPosition}
                type="button"
                width="75%"
                style={{ alignSelf: 'center' }}>
                Invia Posizione
              </Button>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SharePosition
