export type Message = {
  author: 'system' | string
  dateCreated: Date
  body?: string
  attributes?: { type: string } & {}
}

export const Factory = {
  textMessage: (identity: string, body: string): Message => ({
    author: identity,
    dateCreated: new Date(),
    body,
    attributes: { type: 'text' },
  }),
}
