import { GoogleGenerativeAI } from '@google/generative-ai'
import { useQuery } from 'react-query'

const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY || '')
const model = genAI.getGenerativeModel({ model: 'gemini-1.5-flash' })

const useGetWelcomeMessageFromGemini = (options = {}) => {
  return useQuery(
    'welcome-message-from-gemini',
    async (): Promise<string> => {
      const result = await model.generateContent(
        "Manda un saluto caloroso ai nostri amici di Sbam, usando tante emoticon e scrivendo l'ultima frase in un inglese forbito.",
      )
      const response = await result.response
      return response.text()
    },
    {
      ...options,
    },
  )
}

export default useGetWelcomeMessageFromGemini
