import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectorAuth } from 'features/auth/auth.slice'

import Wrapper from 'components/shared/wrapper/wrapper.component'
import { Link as RouterLink } from 'react-router-dom'
import packageJson from '../../../package.json'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import AccessBG from '../../assets/sbam-access-bg.mp4'
import { FcGoogle } from 'react-icons/fc'
import { FaApple } from 'react-icons/fa'
import {
  FacebookAuthProvider,
  OAuthProvider,
  GoogleAuthProvider,
  browserPopupRedirectResolver,
  fetchSignInMethodsForEmail,
  signInWithPopup,
  signInWithCredential,
} from 'firebase/auth'
import { Capacitor } from '@capacitor/core'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { getFirebaseAuth } from 'services/firebase.service'
import { isIos } from 'utils/platform'
import { SignInWithApple } from '@capacitor-community/apple-sign-in'
import shajs from 'sha.js'

const AccessScreen = () => {
  const authState = useSelector(selectorAuth)
  const toast = useToast()
  const isNative = useMemo(() => Capacitor.isNativePlatform(), [])
  const [isLoading, setIsLoading] = useState<boolean | string>(false)

  const switchWebProvider = (providerSlug: string) => {
    switch (providerSlug) {
      case 'google':
        return GoogleAuthProvider
      case 'facebook':
        return FacebookAuthProvider
      default:
        throw new Error('Provider not supported')
    }
  }

  const signInWebWith = (providerSlug: string) => async () => {
    if (isLoading) return
    setIsLoading(providerSlug)
    let providerInstance
    if (providerSlug === 'apple') {
      providerInstance = new OAuthProvider('apple.com')
      providerInstance.addScope('name')
    } else {
      const provider = switchWebProvider(providerSlug)
      providerInstance = new provider()
    }

    try {
      const auth = getFirebaseAuth()
      await signInWithPopup(auth, providerInstance, browserPopupRedirectResolver)
    } catch (error: any) {
      handleSignError(error)
    }
  }

  const switchProvider = (providerSlug: string) => {
    switch (providerSlug) {
      case 'google':
        return FirebaseAuthentication.signInWithGoogle
      case 'facebook':
        return FirebaseAuthentication.signInWithFacebook
      case 'apple':
        return () => FirebaseAuthentication.signInWithApple({ scopes: ['name'] })
      default:
        throw new Error('Provider not supported')
    }
  }

  const signInWithApple = async () => {
    if (!isNative) return signInWebWith('apple')()
    if (isLoading) return

    setIsLoading('apple')

    const rawNonce = `sbam-nonce-${Date.now()}`
    const nonce = shajs('sha256').update(rawNonce).digest('hex')

    const options = {
      clientId: 'com.sbam.app', // Your app's client ID registered with Apple
      redirectURI: `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/__/auth/handler`, // Your app's redirect URI
      scopes: 'email name', // Scopes you want to request from the user
      state: '123456', // A random string to maintain state between request and callback
      nonce, // A unique, random string for each authentication request
    }

    try {
      const signInResult = await SignInWithApple.authorize(options)

      // saves name on LS in order to precompile field later
      localStorage.setItem(
        'apple-signin-cache',
        JSON.stringify({ firstName: signInResult.response.givenName, lastName: signInResult.response.familyName }),
      )

      const auth = getFirebaseAuth()
      const provider = new OAuthProvider('apple.com')
      const appleCredential = provider.credential({
        idToken: signInResult.response.identityToken,
        rawNonce,
      })

      await signInWithCredential(auth, appleCredential)
    } catch (error: any) {
      ;(window as any).a = error
      handleSignError(error)
    }
  }

  const signInWith = (providerSlug: string) => async () => {
    if (!isNative) return signInWebWith(providerSlug)()
    if (isLoading) return
    setIsLoading(providerSlug)
    const signIn = switchProvider(providerSlug)

    const provider = switchWebProvider(providerSlug)

    try {
      const result = await signIn()
      if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const mobileCredential = result.credential
        const token = mobileCredential.idToken
        if (token) {
          const auth = getFirebaseAuth()

          const credential = provider.credential(token)
          await signInWithCredential(auth, credential)
        }
      }
    } catch (error: any) {
      ;(window as any).a = error
      handleSignError(error)
    }
  }

  const handleSignError = useCallback(
    async (error: any) => {
      // Handle Errors here.
      const errorCode = (error.code || '').replace('auth/', '')
      const errorMessage = error.message
      // The email of the user's account used.
      const email = error.customData?.email
      // The AuthCredential type that was used.
      if (errorCode === 'account-exists-with-different-credential') {
        if (email) {
          const signInMethods = await fetchSignInMethodsForEmail(getFirebaseAuth(), email)
          // methods contains the strings of methods, last element is separated by "e"
          const methods = signInMethods
            .map(m => {
              switch (m) {
                case 'password':
                  return 'Email'
                case 'phone':
                  return 'telefono'
                case 'google.com':
                  return 'Google'
                case 'facebook.com':
                  return 'Facebook'
                case 'apple.com':
                  return 'Apple'
                default:
                  return m
              }
            })
            .join(', ')
            .replace(/,([^,]*)$/, ' e$1')
          toast({
            title: 'Account già registrato',
            description: `Questo account è già registrato con ${methods}.`,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } else {
          toast({
            title: 'Account già registrato',
            description: `Questa email è registrata con un altro metodo di accesso.`,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      } else {
        if (
          !errorMessage.includes('com.apple.AuthenticationServices.AuthorizationError 1001') &&
          errorCode !== 'popup-closed-by-user' &&
          errorCode !== 'cancelled-popup-request' &&
          errorMessage !== 'Sign in canceled.' &&
          errorMessage !== 'The user canceled the sign-in flow.'
        ) {
          toast({
            title: 'Errore',
            description: errorMessage,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      }
      setIsLoading(false)
    },
    [toast],
  )

  if (authState.isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <Wrapper justifyContent="center" minH="full" pb="20px" color="white">
      <Flex direction="column" mb="40px" marginTop="20%" textAlign="center">
        <Box maxW="190px" mx="auto">
          <Logo width="100%" />
        </Box>
        {process.env.REACT_APP_IS_BETA != 'true' && (
          <Text fontWeight="600" color="white">
            Benvenutə!
          </Text>
        )}
        {process.env.REACT_APP_IS_BETA == 'true' && (
          <Text fontWeight="600">
            <Text color="red">⚠️ ATTENZIONE ⚠️</Text>
            <Text fontSize="small">Questa è la versione di beta testing dell'app, utile per provare le nuove funzionalità in anteprima.</Text>
            <Text fontSize="small">
              Per partecipare o organizzare eventi reali utilizza la versione non beta scaricabile dagli store o accessibile da{' '}
              <a href="https://app.sbam.social">
                <Text display="inline" textDecoration="underline">
                  app.sbam.social
                </Text>
              </a>
              .
            </Text>
          </Text>
        )}
      </Flex>
      <Box pos="fixed" w="full" h="full" left="0" right="0" zIndex={-1}>
        <video
          loop
          controls={false}
          playsInline={true}
          autoPlay={true}
          muted
          style={{ height: '100%', width: '100%', objectFit: 'cover', zIndex: 0, filter: 'blur(8px)', transform: 'scale(1.1)' }}>
          <source src={AccessBG} type="video/mp4" />
        </video>
        {/* white transparent cover */}
        <Box pos="fixed" w="100%" h="100%" left="0" top="0" bgGradient="linear(to-t, rgba(0,0,0,.6), rgba(0,0,0, .4))" zIndex={1} />
      </Box>
      <Box my="auto">
        {/* {!isNative && <>
          <Box textAlign="center" mb="20px">
            <Button isLoading={isLoading === "google"} disabled={!!isLoading} onClick={signInWebWith("google")} width="100%" color="black" bgColor="white" _hover={{ color: "black" }}>
              <FcGoogle /><Box mx="auto">Continua con Google</Box>
            </Button>
          </Box>
          <Box textAlign="center" mb="20px">
            <Button isLoading={isLoading === "facebook"} disabled={!!isLoading} onClick={signInWebWith("facebook")} width="100%" color="black" bgColor="white" _hover={{ color: "black" }}>
              <FaFacebookF /><Box mx="auto">Continua con Facebook</Box>
            </Button>
          </Box>
        </>}
        {isNative && <>
          <Box textAlign="center" mb="20px">
            <Button isLoading={isLoading === "google"} disabled={!!isLoading} onClick={signInWith("google")} width="100%" color="black" bgColor="white" _hover={{ color: "black" }}>
              <FcGoogle /><Box mx="auto">Continua con Google</Box>
            </Button>
          </Box>
          <Box textAlign="center" mb="20px">
            <Button isLoading={isLoading === "facebook"} disabled={!!isLoading} onClick={signInWith("facebook")} width="100%" color="black" bgColor="white" _hover={{ color: "black" }}>
              <FaFacebookF /><Box mx="auto">Continua con Facebook</Box>
            </Button>
          </Box>
        </>} */}
        {/* <Box textAlign="center" mb="20px">
          <RouterLink to="/signup">
            <Button width="100%" color="black" bgColor="white" _hover={{ color: "black" }}>
              <BsApple /><Box mx="auto">Continua con Apple</Box>
            </Button>
          </RouterLink>
        </Box> */}
        <Box textAlign="center" mb="20px">
          <Button
            isLoading={isLoading === 'google'}
            disabled={!!isLoading}
            onClick={signInWith('google')}
            width="100%"
            color="black"
            bgColor="white"
            _hover={{ color: 'black' }}>
            <FcGoogle />
            <Box mx="auto">Continua con Google</Box>
          </Button>
        </Box>
        {isIos() && (
          <Box textAlign="center" mb="20px">
            <Button
              isLoading={isLoading === 'apple'}
              disabled={!!isLoading}
              onClick={signInWithApple}
              width="100%"
              color="black"
              bgColor="white"
              _hover={{ color: 'black' }}>
              <FaApple />
              <Box mx="auto">Continua con Apple</Box>
            </Button>
          </Box>
        )}
        <RouterLink to={isLoading ? '' : '/login'}>
          <Button
            disabled={!!isLoading}
            width="100%"
            mb="20px"
            variant="outline"
            borderColor="white"
            color="white"
            _hover={{ color: 'black', bgColor: 'white' }}>
            Continua con email
          </Button>
        </RouterLink>
      </Box>
      <Box mt="auto" bottom="0" textAlign="center" pt="5px">
        <Text fontSize="xs">
          Sbam! {Capacitor.getPlatform()} v{packageJson.version}
          {process.env.REACT_APP_IS_BETA == 'true' && `(beta) | ${process.env.REACT_APP_APP_URL}/socials.json`}
        </Text>
      </Box>
    </Wrapper>
  )
}

export default AccessScreen
