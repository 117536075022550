import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'features'
import { selectorAuth } from 'features/auth/auth.slice'

import InputField from 'components/shared/input/input.component'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { loginAction } from 'features/auth/auth.actions'
import { fetchSignInMethodsForEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { Link as RouterLink, createSearchParams } from 'react-router-dom'
import { getFirebaseAuth } from 'services/firebase.service'
import packageJson from '../../../package.json'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ArrowBackIcon } from '@chakra-ui/icons'

const LoginScreen = () => {
  const toast = useToast()
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (errors.email) {
      toast({
        title: 'Inserisci email',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
    if (errors.password) {
      toast({
        title: 'Inserisci password',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
    }
  }, [errors])

  const dispatch = useAppDispatch()
  const authState = useSelector(selectorAuth)
  const onSubmit = async (data: any) => {
    const auth = getFirebaseAuth()
    if (!showPassword) {
      // Check if user email exists in Firebase
      const email = data.email
      const methods = await fetchSignInMethodsForEmail(auth, email)
      if (methods.indexOf('password') > -1) {
        setShowPassword(true)
        return
      } else if (methods.length > 0) {
        toast({
          title: 'Email già registrata con ' + methods.join(', '),
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
        return
      } else {
        return navigate('/signup?' + createSearchParams({ email }))
      }
    }

    try {
      setIsLoading(true)
      const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password)
      if (userCredential) {
        dispatch<any>(loginAction())
      } else {
        console.log('something went wrong')
      }
    } catch (err) {
      console.log(err)
      toast({
        title: 'Credenziali non corrette',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'top',
      })
      setIsLoading(false)
    }
  }

  const email = watch('email')

  // if email changes reset show password
  useEffect(() => {
    setShowPassword(false)
  }, [email])

  const handleBack = () => {
    if (showPassword) return setShowPassword(false)
    navigate(-1)
  }

  if (authState.isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <Wrapper justifyContent="center" minH="full" pb="20px" position="relative">
      <form style={{ display: 'contents' }} onSubmit={handleSubmit(onSubmit)}>
        <Button
          justifyContent="left"
          onClick={handleBack}
          fontSize="md"
          mt={10}
          fontWeight="bold"
          variant="link"
          leftIcon={<ArrowBackIcon boxSize={7} />}>
          Indietro
        </Button>
        <Box maxW="190px" mx="auto" marginTop="20%">
          <Logo width="100%" />
        </Box>
        <Box my="auto">
          <Flex direction="column" mb="20px" textAlign="center">
            {/* {process.env.REACT_APP_IS_BETA != "true" && <Text fontWeight="600">Ti diamo il benvenuto in Sbam!</Text>} */}
            {process.env.REACT_APP_IS_BETA == 'true' && (
              <Text fontWeight="600">
                <Text color="red">⚠️ ATTENZIONE ⚠️</Text>
                <Text fontSize="small">Questa è la versione di beta testing dell'app, utile per provare le nuove funzionalità in anteprima.</Text>
                <Text fontSize="small">
                  Per partecipare o organizzare eventi reali utilizza la versione non beta scaricabile dagli store o accessibile da{' '}
                  <a href="https://app.sbam.social">
                    <Text display="inline" textDecoration="underline">
                      app.sbam.social
                    </Text>
                  </a>
                  .
                </Text>
              </Text>
            )}
          </Flex>
          <Flex direction="column">
            <Box mb="20px">
              <InputField type="email" {...register('email', { required: true })} placeholder="Email" />
            </Box>
            {showPassword && (
              <>
                <Box mb="20px">
                  <InputField type="password" {...register('password', { required: true })} placeholder="Password" />
                </Box>
                <Box textAlign="center" mb="20px">
                  <Text>
                    <RouterLink to="/forgot-password">
                      <Text display="inline" textDecoration="underline">
                        Password dimenticata
                      </Text>
                    </RouterLink>
                  </Text>
                </Box>
              </>
            )}
            <Button width="100%" mb="20px" type="submit" isLoading={isLoading}>
              {showPassword && 'Accedi'}
              {!showPassword && 'Continua'}
            </Button>
          </Flex>
        </Box>
        {/* <Box mt="auto">
          <Box mb="20px" textAlign="center">
            <Text>
              <RouterLink to="/signup">
                <Button width="100%" variant="outline" type="submit" isLoading={isLoading} >
                  Crea un account
                </Button>
              </RouterLink>
            </Text>
          </Box>
        </Box> */}
        <Box bottom="0" textAlign="center" pt="5px">
          <Text fontSize="xs">
            Sbam! v{packageJson.version}
            {process.env.REACT_APP_IS_BETA == 'true' && ' (beta)'}
          </Text>
        </Box>
      </form>
    </Wrapper>
  )
}

export default LoginScreen
