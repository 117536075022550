import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useChatContext } from '../context/chat-context'
import SharePosition from './shared-position'
import Photocamera from './photocamera'
import Poll from './poll'
function ChatPlusMenu() {
  const { isChatPlusMenuVisible } = useChatContext()

  if (!isChatPlusMenuVisible) {
    return <></>
  }

  return (
    <Flex
      position="absolute"
      bottom="4rem"
      backgroundColor="white"
      py="1.2rem"
      px="1.2rem"
      direction="column"
      justifyContent="space-evenly"
      width="65%"
      mb="10px"
      gap="1rem"
      borderWidth="1px"
      borderRadius="5px">
      <Poll />

      <SharePosition />

      <Photocamera />
    </Flex>
  )
}

export default ChatPlusMenu
