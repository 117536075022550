import React from 'react'
import { Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Checkbox, CheckboxGroup, Stack, Flex } from '@chakra-ui/react'
import { CheckboxIcon } from 'components/shared/icons/icons'
import { Category } from 'typings/database.d'
import { useQuery } from 'react-query'
import Api from 'services/api/api.service'
import Wrapper from 'components/shared/wrapper/wrapper.component'

const CategoriesScreen = () => {
  const {
    isLoading: isLoadingCategories,
    error,
    data: categories,
  } = useQuery('categories', () => Api.categories(), {
    retry: false,
  }) as {
    error: String
    isLoading: boolean
    data: any
  }

  return (
    <Wrapper pt="20px" pb="90px">
      <Flex w="100%" alignSelf="center" justify="space-between" direction="column" my="20px">
        <Accordion>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Svago
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel paddingLeft="5%">
              <CheckboxGroup defaultValue={['naruto', 'kakashi']}>
                <Stack>
                  <label>
                    Videogiochi
                    <Checkbox size="lg" icon={<CheckboxIcon boxSize={6} />} colorScheme="whiteAlpha" value="Videogiochi" float="right"></Checkbox>
                  </label>
                  <label>
                    Food
                    <Checkbox size="lg" icon={<CheckboxIcon boxSize={6} />} colorScheme="whiteAlpha" value="Food" float="right"></Checkbox>
                  </label>
                  <label>
                    Sottocategoria
                    <Checkbox size="lg" icon={<CheckboxIcon boxSize={6} />} colorScheme="whiteAlpha" value="Sottocategoria" float="right"></Checkbox>
                  </label>
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Categoria
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel paddingLeft="5%"></AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Categoria
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel paddingLeft="5%"></AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </Wrapper>
  )
}
export default CategoriesScreen
