import { Box, Flex, Text } from '@chakra-ui/react'
import { Conversation, Message, User } from '@twilio/conversations'
import { AppContext } from 'app.context'
import { NotificationArrow } from 'components/shared/icons/icons'
import { ChatAttributes, useGetChatAttribute, useGetLastMessage, useUnreadCounters } from 'hooks/use-chat'
import moment from 'moment'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import useGetConversationQueryBySid from 'screens/chat/hooks/use-get-conversation-query'

function Chat({ chat }: { chat: Conversation }) {
  const { data: lastMessage } = useGetLastMessage(chat) as { data: Message }

  return (
    <Link className="chat" to={`/event/${chat.sid}/chat`} key={chat.sid}>
      <Flex alignItems="center" width="100%" justifyContent="space-between" mb="20px">
        <Flex alignItems="center" width="100%">
          <Avatar chat={chat} />

          <Flex direction="column" flexGrow={1} overflow="hidden" paddingRight="10px">
            <Name chat={chat} />

            <Flex justifyContent="space-between" alignItems="center" gap=".5rem">
              <Box overflow="hidden" fontSize="12px" fontWeight="600" marginLeft="10px" paddingRight="10px">
                <Preview chat={chat} />
              </Box>

              <Box fontSize="12px" fontWeight="600" flexShrink={0}>
                {lastMessage?.dateCreated && moment(lastMessage?.dateCreated).fromNow()}
              </Box>
            </Flex>
          </Flex>

          <NotificationArrow flexShrink={0} />
        </Flex>
      </Flex>
    </Link>
  )
}

function Avatar({ chat }) {
  switch (chat.attributes.type) {
    case 'userChat':
      return <UserAvatar chat={chat} />

    default:
      return <ChatAvatar chat={chat} />
  }
}

function ChatAvatar({ chat }: { chat: Conversation }) {
  const { data: attributes, isSuccess } = useGetChatAttribute(chat) as { data: ChatAttributes; isSuccess: boolean }
  const { unreadCounters } = useUnreadCounters(chat)

  if (!isSuccess) {
    return (
      <Box
        w="38px"
        h="38px"
        position="relative"
        flexShrink={0}
        _after={
          unreadCounters[chat.sid]
            ? {
                content: `'${unreadCounters[chat.sid]}'`,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bottom: '-4.5px',
                right: '-4.5px',
                width: '17px',
                height: '17px',
                border: '2px solid #FFFFFF',
                color: '#FFFFFF',
                borderRadius: '50%',
                backgroundColor: 'brand.500',
                fontSize: '10px',
                fontWeight: '700',
              }
            : {}
        }
        bgPos="center"
        bgSize="cover"
        borderRadius={'50%'}></Box>
    )
  }

  return (
    <Box
      w="38px"
      h="38px"
      position="relative"
      flexShrink={0}
      _after={
        unreadCounters[chat.sid]
          ? {
              content: `'${unreadCounters[chat.sid]}'`,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bottom: '-4.5px',
              right: '-4.5px',
              width: '17px',
              height: '17px',
              border: '2px solid #FFFFFF',
              color: '#FFFFFF',
              borderRadius: '50%',
              backgroundColor: 'brand.500',
              fontSize: '10px',
              fontWeight: '700',
            }
          : {}
      }
      bgImage={attributes.data?.coverUrl ? `url('${attributes.data.coverUrl}')` : ''}
      bgPos="center"
      bgSize="cover"
      borderRadius={'50%'}>
      {!attributes.data?.coverUrl && <DefaultAvatar />}
    </Box>
  )
}

function UserAvatar({ chat }: { chat: Conversation }) {
  const { data, isSuccess } = useGetOtherPartecipantBySid(chat.sid)
  const { unreadCounters } = useUnreadCounters(chat)

  const participantAttributes = data?.attributes as { name: string; avatar: string }
  const avatar = participantAttributes?.avatar
  const bgImage = avatar ? `url('${avatar}')` : ''

  if (!isSuccess) {
    return (
      <Box
        w="38px"
        h="38px"
        position="relative"
        flexShrink={0}
        _after={
          unreadCounters[chat.sid]
            ? {
                content: `'${unreadCounters[chat.sid]}'`,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bottom: '-4.5px',
                right: '-4.5px',
                width: '17px',
                height: '17px',
                border: '2px solid #FFFFFF',
                color: '#FFFFFF',
                borderRadius: '50%',
                backgroundColor: 'brand.500',
                fontSize: '10px',
                fontWeight: '700',
              }
            : {}
        }
        bgPos="center"
        bgSize="cover"
        borderRadius={'50%'}></Box>
    )
  }

  return (
    <Box
      w="38px"
      h="38px"
      position="relative"
      flexShrink={0}
      _after={
        unreadCounters[chat.sid]
          ? {
              content: `'${unreadCounters[chat.sid]}'`,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bottom: '-4.5px',
              right: '-4.5px',
              width: '17px',
              height: '17px',
              border: '2px solid #FFFFFF',
              color: '#FFFFFF',
              borderRadius: '50%',
              backgroundColor: 'brand.500',
              fontSize: '10px',
              fontWeight: '700',
            }
          : {}
      }
      bgImage={bgImage}
      bgPos="center"
      bgSize="cover"
      borderRadius={'50%'}>
      {!bgImage && <DefaultAvatar />}
    </Box>
  )
}

function DefaultAvatar() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="Raggruppa_154" data-name="Raggruppa 154" transform="translate(-28 -203)">
        <circle id="Ellisse_71" data-name="Ellisse 71" cx="19" cy="19" r="19" transform="translate(28 203)" fill="#011936" />
        <g id="Raggruppa_150" data-name="Raggruppa 150" transform="translate(2581 -3276)">
          <path
            id="Unione_9"
            data-name="Unione 9"
            d="M16,17H0A8.014,8.014,0,0,1,5.577,9.375a5,5,0,1,1,4.845,0A8.014,8.014,0,0,1,16,17h0Z"
            transform="translate(-2542 3488)"
            fill="#fff"
          />
          <path
            id="Sottrazione_13"
            data-name="Sottrazione 13"
            d="M23,18H18a9.02,9.02,0,0,0-4.644-7.874A5.971,5.971,0,0,0,15,6c0-.152-.006-.3-.017-.456a4,4,0,0,1,4.143,6.844A6.013,6.013,0,0,1,23,18Z"
            transform="translate(-2543 3487)"
            fill="#fff"
          />
          <path
            id="Sottrazione_14"
            data-name="Sottrazione 14"
            d="M5,18H0a6.011,6.011,0,0,1,3.874-5.611A4,4,0,0,1,8.017,5.545C8.006,5.693,8,5.846,8,6a5.972,5.972,0,0,0,1.643,4.126A9.018,9.018,0,0,0,5,18Z"
            transform="translate(-2548 3487)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

function Name({ chat }) {
  switch (chat.attributes.type) {
    case 'userChat':
      return <UtentiChatName sid={chat.sid} />

    default:
      return (
        <Box fontSize="16px" fontWeight="600" marginLeft="10px">
          {chat.friendlyName}
        </Box>
      )
  }
}

function UtentiChatName({ sid }) {
  const { data, isLoading } = useGetOtherPartecipantBySid(sid)

  const attributes = data?.attributes as { name: string; avatar: string }
  const name = attributes?.name

  if (isLoading) {
    return (
      <Box fontSize="16px" fontWeight="600" marginLeft="10px">
        Caricamento...
      </Box>
    )
  }

  return (
    <Box fontSize="16px" fontWeight="600" marginLeft="10px">
      {name}
    </Box>
  )
}

function Preview({ chat }: { chat: Conversation }) {
  const { data: lastMessage, isLoading } = useGetLastMessage(chat) as { data: Message; isLoading: boolean }

  if (isLoading) {
    return (
      <Text as="label" opacity=".5" fontStyle="italic">
        Caricamento...
      </Text>
    )
  }

  if (!lastMessage) {
    return (
      <Text as="label" opacity=".5" fontStyle="italic">
        Scrivi tu il primo messaggio
      </Text>
    )
  }

  const attributes = lastMessage?.attributes as any // FIXME: help

  if (attributes?.deleted) {
    return <Text>Messaggio cancellato</Text>
  }

  switch (attributes?.type) {
    case 'location':
      return <Text>Mappa</Text>

    case 'media':
      return <Text>Foto</Text>

    case 'poll':
      return <Text>Sondaggio</Text>

    case 'text':
      return (
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace={'nowrap'}>
          {lastMessage?.body}
        </Text>
      )

    default: // Retrocompatibility: {type: reply} and old text message without attributes, just message.body
      return (
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace={'nowrap'}>
          {lastMessage?.body}
        </Text>
      )
  }
}

const useGetOtherPartecipantBySid = sid => {
  const { client: twilioClient } = useContext(AppContext)

  const { data, isSuccess } = useGetConversationQueryBySid(sid)
  const self = data?.conversation?.self

  const identity = twilioClient?.user.identity

  return useQuery(
    `other-partecipant-${sid}`,
    async () => {
      const partecipants = await self!.getParticipants()
      const users = await Promise.all(partecipants.map(p => p.getUser()))
      const user: User | undefined = users.find(u => u.identity !== identity)

      return user
    },
    { enabled: isSuccess },
  )
}

export default Chat
