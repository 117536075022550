import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { FaArrowLeft } from 'react-icons/fa6'
import history from 'utils/history'

function Header() {
  return (
    <>
      <Flex
        alignItems="center"
        bgPos="center"
        bgSize="cover"
        borderBottomRadius="15px"
        boxShadow="inset 0 0 0 2000px rgba(1, 25, 54, .5)"
        color="#FFFFFF"
        flexShrink="0"
        fontSize="18px"
        fontWeight="600"
        height="60px"
        justifyContent="space-between"
        left="0"
        position="absolute"
        px="20px"
        top="0"
        width="100%"
        zIndex="50">
        <Flex alignItems="center">
          <Box cursor="pointer" marginRight="20px" onClick={history.back}>
            <FaArrowLeft />
          </Box>

          <Name />
        </Flex>
      </Flex>

      <Box h="60px"></Box>
    </>
  )
}

function Name() {
  return <span>Organizza</span>
}

export default Header
