import React, { useState } from 'react'

import {
  Box,
  Center,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import QRReader from '../qr-reader/qr-reader'
import Api from 'services/api/api.service'
import { AxiosError } from 'axios'
const ScanQRCodeComponent = (props: { eventId: string; onApprove: Function }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const handleQR = async (payload: string) => {
    try {
      setLoading(true)
      const { attendanceId, attendanceCode } = JSON.parse(payload)
      await Api.approveAttendance(props.eventId, attendanceId, attendanceCode)
      onClose()
      props.onApprove()
      setLoading(false)
    } catch (e) {
      alert('Codice QR non valido: ' + ((e as AxiosError).response?.data as any).message)
      setLoading(false)
    }
  }

  return (
    <>
      <Box mr="15px" bg="#FF7436" p="6px 8px" onClick={onOpen} borderRadius="15px" color="white">
        <Center>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <g id="Raggruppa_123" data-name="Raggruppa 123" transform="translate(-333.5 -324.5)">
              <g id="Raggruppa_123-2" data-name="Raggruppa 123" transform="translate(-26 -19)">
                <line
                  id="Linea_54"
                  data-name="Linea 54"
                  y1="6"
                  transform="translate(361 345)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
                <line
                  id="Linea_55"
                  data-name="Linea 55"
                  x2="6"
                  transform="translate(361 345)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
              </g>
              <g id="Raggruppa_126" data-name="Raggruppa 126" transform="translate(714 689) rotate(180)">
                <line
                  id="Linea_54-2"
                  data-name="Linea 54"
                  y1="6"
                  transform="translate(361 345)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
                <line
                  id="Linea_55-2"
                  data-name="Linea 55"
                  x2="6"
                  transform="translate(361 345)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
              </g>
              <g id="Raggruppa_124" data-name="Raggruppa 124" transform="translate(698 -35) rotate(90)">
                <line
                  id="Linea_54-3"
                  data-name="Linea 54"
                  y1="6"
                  transform="translate(361 345)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
                <line
                  id="Linea_55-3"
                  data-name="Linea 55"
                  x2="6"
                  transform="translate(361 345)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
              </g>
              <g id="Raggruppa_125" data-name="Raggruppa 125" transform="translate(-10 705) rotate(-90)">
                <line
                  id="Linea_54-4"
                  data-name="Linea 54"
                  y1="6"
                  transform="translate(361 345)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
                <line
                  id="Linea_55-4"
                  data-name="Linea 55"
                  x2="6"
                  transform="translate(361 345)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
              </g>
            </g>
          </svg>
          <Text ml="6px">Scansiona</Text></Center>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scansiona QR partecipante</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="40px">
            <Center>
              {!loading && <QRReader onQrFound={handleQR} />}
              {loading && <Text>Invio partecipazione</Text>}
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ScanQRCodeComponent
