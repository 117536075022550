import { Box } from '@chakra-ui/react'
import React from 'react'
import { FaPaperclip } from 'react-icons/fa'
import { useChatContext } from '../context/chat-context'

function ChatPlusMenuToggle() {
  const { setIsChatPlusMenuBoxVisible } = useChatContext()

  const handleOnClick = () => {
    setIsChatPlusMenuBoxVisible(b => !b)
  }

  return (
    <Box color="brand.500" cursor="pointer" fontSize="1.5rem">
      <FaPaperclip onClick={handleOnClick} />
    </Box>
  )
}

export default ChatPlusMenuToggle
