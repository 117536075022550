import { Box, Button, Flex, InputGroup } from '@chakra-ui/react'
import ChatMessage from '@screens/chat/chat-messages'
import { AppContext } from 'app.context'
import { I } from 'components/shared/input/input.style'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { PiPaperPlaneRightFill } from 'react-icons/pi'
import ChatBoxFooter from './chat-box-footer'
import ChatBoxHeader from './chat-box-header'
import Header from './header'
import { Factory, Message } from './types'
import useGetWelcomeMessageFromGemini from './use-get-welcome-message-from-gemini'
import useSendMessageToGeminiMutation from './use-send-message-to-gemini-mutation'

const CreateEventChatBot = () => {
  const [messages, setMessages] = useState<Message[]>([])
  const chatRef = useRef<HTMLInputElement>(null)

  const sendMessage = useSendMessageToGeminiMutation({
    onSuccess: data => {
      setMessages(rest => rest.concat(Factory.textMessage('system', data)))
    },
  })

  const { isLoading, isError } = useGetWelcomeMessageFromGemini({
    onSuccess: data => {
      setMessages([Factory.textMessage('system', data)])
    },
  })

  useScrollToTheEndWhenNewMessageArrive(chatRef, messages)

  if (isError) {
    return <></>
  }

  if (isLoading) {
    return (
      <Wrapper h="100%" pos="relative" px="0">
        <Header />

        <Flex
          ref={chatRef}
          className="chat-screen"
          direction="column"
          width="100%"
          height="100%"
          pt="26px"
          px=".5rem"
          gap="1.25rem"
          overflowY="scroll">
          <LoadingMessage isLoading />
        </Flex>

        <Flex direction="column">
          <Box h="75px"></Box>
        </Flex>
      </Wrapper>
    )
  }

  return (
    <Wrapper h="100%" pos="relative" px="0">
      <Header />

      <Flex ref={chatRef} className="chat-screen" direction="column" width="100%" height="100%" pt="26px" px=".5rem" gap="1.25rem" overflowY="scroll">
        {messages.map((msg, index) => (
          <ChatBox msg={msg} key={index} />
        ))}

        <LoadingMessage isLoading={sendMessage.isLoading} />
      </Flex>

      <Flex direction="column">
        <Box h="75px"></Box>

        <ChatInputText chatRef={chatRef} sendMessage={sendMessage} setMessages={setMessages} />
      </Flex>
    </Wrapper>
  )
}

function ChatBox({ msg }: { msg: Message }) {
  const isMine = msg.author !== 'system'

  return (
    <Flex className="chat-box" width="100%" gap="1rem" alignItems="center" justifyContent={isMine ? 'end' : 'start'}>
      <Flex
        backgroundColor={isMine ? 'rgba(255, 116, 54, .1)' : '#F7F7F7'}
        borderRadius="1rem"
        direction="column"
        padding="10px 10px"
        gap=".5rem"
        width="75%">
        <ChatBoxHeader msg={msg} />

        <ChatMessage msg={msg} />

        <ChatBoxFooter msg={msg} />
      </Flex>
    </Flex>
  )
}

function ChatInputText({ chatRef, sendMessage, setMessages }) {
  const { client: twilioClient } = useContext(AppContext)
  const identity = twilioClient?.user.identity

  const [value, setValue] = useState<string>('')
  const isSubmitDisabled = !value || !identity

  const handleOnInput = ({ currentTarget: { value } }) => {
    setValue(value)
  }

  const handleOnKeyDown = event => {
    if (event.key === 'Enter') {
      if (isSubmitDisabled) {
        return
      }

      setMessages(rest => rest.concat(Factory.textMessage(identity, event.target.value)))
      sendMessage.mutate(event.target.value)
      setValue('')
    }
  }

  const handleOnSendMessage = () => {
    if (isSubmitDisabled) {
      return
    }

    setMessages(rest => rest.concat(Factory.textMessage(identity, value)))
    sendMessage.mutate(value)
    setValue('')
  }

  return (
    <Flex alignItems="center" px="1rem" gap="1rem" my="10px" width="100%" position="absolute" left="0" bottom="0">
      <InputGroup>
        <I type="text" placeholder="Scrivi un messaggio" onInput={handleOnInput} onKeyDown={handleOnKeyDown} value={value} />
      </InputGroup>

      <Button isDisabled={isSubmitDisabled} variant="ghost">
        <Box color="brand.500" fontSize="1.5rem">
          <PiPaperPlaneRightFill onClick={handleOnSendMessage} />
        </Box>
      </Button>
    </Flex>
  )
}

function LoadingMessage({ isLoading }) {
  if (isLoading) {
    return <ChatMessage msg={{ body: 'Caricamento...', attributes: { type: 'text' } }} />
  }

  return <></>
}

const useScrollToTheEndWhenNewMessageArrive = (chatRef, messages) => {
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current?.scrollHeight
    }
  }, [chatRef, messages]) // Put 'messages' as deps do the trick
}

export default CreateEventChatBot
