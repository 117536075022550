import { AspectRatio, Flex, Text } from '@chakra-ui/react'
import { selectorAuth } from 'features/auth/auth.slice'
import moment from 'moment'
import React from 'react'
import { IoIosPin, IoMdCalendar } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Event } from 'typings/database'
import { calculateDistance } from 'utils/calculate-distance.util'

const EventPreview = ({ title, coverUrl, startDate, location: { fakePosition, province } }: Event) => {
  const position = useSelector(selectorAuth).position

  const eventPosition = {
    lat: fakePosition.coordinates[1],
    lon: fakePosition.coordinates[0],
  }

  return (
    <AspectRatio
      className="events-preview"
      ratio={320 / 150}
      bg={`url('${coverUrl}') center`}
      bgSize="cover"
      maxW="500px"
      borderRadius="15px"
      overflow="hidden">
      <Flex
        p="1rem 1.25rem"
        color="#FFFFFF"
        bg="rgba(1,25,54,.50)"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <EventTitle title={title} />

        <Flex direction="column" gap=".5rem" width="100%">
          <Flex direction="column" gap=".25rem">
            <EventDate startDate={startDate} />

            <EventPosition province={province} position={position} eventPosition={eventPosition} />
          </Flex>
        </Flex>
      </Flex>
    </AspectRatio>
  )
}

function EventTitle({ title }) {
  if (!title) {
    return <></>
  }

  return (
    <Text
      fontWeight="bold"
      fontSize="1.1rem"
      lineHeight="1.2"
      pr="5px"
      style={{
        display: '-webkit-box',
        maxWidth: '100%',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      }}>
      {title}
    </Text>
  )
}

function EventDate({ startDate }) {
  if (!startDate) {
    return <></>
  }

  return (
    <Flex gap=".25rem" alignItems="center">
      <IoMdCalendar />

      <Text fontSize="14px" dangerouslySetInnerHTML={{ __html: moment(startDate).format('<b>D MMMM YYYY</b> [alle] <b>h:mm A z</b>') }}></Text>
    </Flex>
  )
}

function EventPosition({ position, eventPosition, province }) {
  const provinceName = province?.name

  if (!position || !eventPosition) {
    return <></>
  }

  if (!provinceName) {
    return (
      <Flex gap=".25rem" alignItems="center">
        <IoIosPin />

        <Text as="span" fontSize="14px">
          a <b>{calculateDistance(position, eventPosition)} km</b> da te
        </Text>
      </Flex>
    )
  }

  return (
    <Flex gap=".25rem" alignItems="center">
      <IoIosPin />

      <Text as="span" fontSize="14px">
        a <b>{calculateDistance(position, eventPosition)} km</b> da te (<b>{provinceName}</b>)
      </Text>
    </Flex>
  )
}

export default EventPreview
