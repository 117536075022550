import { Button, Flex, Text, Box, useToast, Grid, GridItem, Heading, Link } from '@chakra-ui/react'
import React, { ReactNode, FC, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { selectorAuth, setAuthState, setIntroSeen, setUser } from 'features/auth/auth.slice'
import Api from 'services/api/api.service'
import { Notification } from 'typings/database.d'

import Wrapper from 'components/shared/wrapper/wrapper.component'
import NotificationRow from 'components/shared/notification-row/notification-row.component'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

import { Navigation, Pagination, Scrollbar, A11y, Swiper as SwiperType } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import 'screens/intro/intro.css'
import { useAppDispatch } from 'features'
import intro1 from 'assets/intro-1.svg'
import intro2 from 'assets/intro-2.svg'
import intro3 from 'assets/intro-3.svg'
import intro4 from 'assets/intro-4.svg'
import intro5 from 'assets/intro-5.svg'
import { useNavigate } from 'react-router-dom'

const Slide: FC<{ onClick: () => void; bg: string; label?: string; children: ReactNode; disabled?: boolean }> = ({
  onClick,
  bg,
  children,
  label = 'Inizia',
  disabled = false,
}) => {
  return (
    <Flex h="100%" direction="column" justifyContent="center" bg={`url(${bg}) no-repeat bottom`} bgSize="contain">
      <Flex flex="1" direction="column" pt="20%" alignItems="center" bgGradient="linear(to-b, rgba(255,255,255,1), rgba(255,255,255,0))">
        <Text fontSize="20px" p={30} color="#011936" fontWeight="500" width="100%" maxW="480px">
          {children}
        </Text>
      </Flex>
      <Wrapper>
        <Button
          pos="absolute"
          disabled={disabled}
          bg="white"
          zIndex="20"
          position="relative"
          onClick={onClick}
          mb="30px"
          mr="30px"
          justifySelf="flex-end"
          ml="auto"
          variant="ghost">
          {label}
          <svg style={{ marginLeft: '15px' }} width="8.832" height="14.836" viewBox="0 0 8.832 14.836">
            <path
              id="Tracciato_479"
              data-name="Tracciato 479"
              d="M-8725.873,3716.325l6.711,6.711-6.711,6.711"
              transform="translate(8726.58 -3715.618)"
              fill="none"
              stroke="#ff7436"
              stroke-width="2"
            />
          </svg>
        </Button>
      </Wrapper>
    </Flex>
  )
}

const IntroScreen = () => {
  const [swiper, setSwiper] = useState<SwiperType>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const timerRef = useRef<number>()
  const [progress, setProgress] = useState(0)
  const resetTimer = () => {
    setProgress(0)
    window.clearTimeout(timerRef.current)
    window.setTimeout(() => {
      setProgress(1)
    }, 0)
    timerRef.current = window.setTimeout(() => {
      setProgress(0)
    }, 3500)
  }
  useEffect(() => {
    resetTimer()
    return () => {
      window.clearTimeout(timerRef.current)
    }
  }, [])
  return (
    // <Wrapper height="100%">
    <Flex flex={1} direction="column" h="100%">
      <Box bg="brand.500" h="5px" width={progress ? '100%' : '0%'} transition={progress ? 'width 3.5s linear' : 'none'}></Box>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        navigation
        pagination={{ clickable: true }}
        style={{ width: '100%', height: '100%' }}
        onPaginationUpdate={() => resetTimer()}
        onSwiper={swiper => setSwiper(swiper)}>
        <SwiperSlide>
          <Slide
            disabled={progress === 1}
            bg={intro1}
            onClick={() => {
              swiper?.slideTo(1)
              resetTimer()
            }}
            label="Come?">
            <Box textAlign="center" w="full">
              <svg xmlns="http://www.w3.org/2000/svg" width="78.637" height="113.587" style={{ margin: '0 auto 20px' }} viewBox="0 0 78.637 113.587">
                <path
                  id="Tracciato_41"
                  data-name="Tracciato 41"
                  d="M387.649,160.241l-39.854-19.472s-50.909,28.028-36.072,77.594c0,0,12.508-10.622,41.308-9.441,0,0-23.8,10.827-20.655,45.435,0,0,13.091-25.963,38.982-35.7l5.236-30.766s-31.127-10.243-54.69,12.474c0,0,13.673-30.094,65.745-40.124"
                  transform="translate(-309.012 -140.769)"
                  fill="#ff7436"
                />
              </svg>
            </Box>
            Benvenut* in Sbam! <br /> <br />
            Qui puoi conoscere nuove persone con cui divertirti e fare amicizia.
            <br /> <br />
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide
            disabled={progress === 1}
            bg={intro2}
            onClick={() => {
              swiper?.slideTo(2)
              resetTimer()
            }}
            label="Avanti">
            Ti basta partecipare agli eventi che più ami.
            <br />
            Puoi anche organizzare, è semplicissimo e velocissimo.
            <br />
            <br />
            Decidi tu quando, dove e come socializzare.
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide
            bg={intro4}
            disabled={progress === 1}
            onClick={() => {
              // swiper?.slideTo(3)
              // resetTimer()
              dispatch(setIntroSeen(true))
              navigate('/')
            }}>
            Per svolgere gli eventi ti occorrono le saette{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.905"
              height="15.752"
              viewBox="0 0 10.905 15.752"
              style={{ display: 'inline', marginBottom: '2px' }}>
              <path
                id="Tracciato_41"
                data-name="Tracciato 41"
                d="M319.917,143.469l-5.527-2.7s-7.06,3.887-5,10.761c0,0,1.735-1.473,5.729-1.309,0,0-3.3,1.5-2.864,6.3a11.218,11.218,0,0,1,5.406-4.951l.726-4.267a7.969,7.969,0,0,0-7.584,1.73s1.9-4.173,9.117-5.564"
                transform="translate(-309.012 -140.769)"
                fill="#ff7436"
              />
            </svg>
            .<br />
            Le ottieni gratis come ricompense.
            <br />
            <br />
            Se preferisci puoi anche acquistarle sul nostro store.
          </Slide>
        </SwiperSlide>
        {/* <SwiperSlide>
          <Slide
            bg={intro5}
            disabled={progress === 1}
            onClick={() => {
              dispatch(setIntroSeen(true))
              navigate('/')
            }}>
              One more thing…<br /><br />
              Se ancora non l’hai fatto, unisciti ora alla nostra community! <br /><br />
              Potrai condividere gli eventi dell’app e le relative foto e video con gli altri Sbammers<br /><br />
              Ti aspettiamo qui:<br />
              <Link href="https://t.me/+tlnMwDn85qllMjM0" target="_blank">https://t.me/+tlnMwDn85qllMjM0</Link>
          </Slide>
        </SwiperSlide> */}
      </Swiper>
    </Flex>
    // </Wrapper>
  )
}

export default IntroScreen
