import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { Flex, Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Chat from '../chat'
import EnableGpsModal from '../enable-gps-modal'
import EmptyMessage from './empty-message'
import Tabs from './tabs'
import useGetConversationByPage from './use-get-conversation-by-page'

export type LOCATION_PERMISSION_STATUS = 'GPS_OFF' | 'LOCATION_PERMISSION_DENIED' | 'PERMISSION_GRANTED' | 'RUN_REQUEST_PERMISSION'

const isNative = Capacitor.isNativePlatform()

const ChatListScreen = () => {
  const { page } = useParams()
  const [locationPermissionStatus, setLocationPermissionStatus] = useState<LOCATION_PERMISSION_STATUS>('RUN_REQUEST_PERMISSION')

  const {
    conversations,
    query: [firstPage, rest],
  } = useGetConversationByPage(page)
  const { isIdle, isLoading, isSuccess } = firstPage
  const { isIdle: isRestIdle, isLoading: isRestLoading } = rest

  useEffect(() => {
    async function checkGpsPermission() {
      if (!isNative) {
        return
      }

      if (locationPermissionStatus === 'RUN_REQUEST_PERMISSION') {
        try {
          const { location, coarseLocation } = await Geolocation.requestPermissions()

          if (coarseLocation === 'granted' || location === 'granted') {
            setLocationPermissionStatus('PERMISSION_GRANTED')
            return
          }

          setLocationPermissionStatus('LOCATION_PERMISSION_DENIED')
        } catch (e) {
          console.error(e)
          setLocationPermissionStatus('GPS_OFF')
        }
      }
    }
    checkGpsPermission()
  }, [locationPermissionStatus])

  if (isIdle || isRestIdle || isLoading || isRestLoading) {
    return (
      <Flex className="chat-list-screen" height="calc(100vh - 125px)" direction="row" justifyContent="center" alignItems="center">
        <Spinner size="sm" mr="10px" /> {isIdle ? 'Attesa' : 'Caricamento'} lista chat
      </Flex>
    )
  }

  if (!isSuccess) {
    return <></>
  }

  if (isNative && locationPermissionStatus !== 'PERMISSION_GRANTED') {
    return <EnableGpsModal locationPermissionStatus={locationPermissionStatus} setLocationPermissionStatus={setLocationPermissionStatus} />
  }

  return (
    <Flex className="chat-list-screen" height="calc(100vh - 125px)" direction="column" ml="auto" mr="auto" maxWidth="500px">
      <Tabs />

      <Flex direction="column" width="100%" height="100%" px="20px" overflowY="scroll">
        <EmptyMessage />

        {conversations?.map(chat => {
          return <Chat key={chat.sid} chat={chat} />
        })}
      </Flex>
    </Flex>
  )
}

export default ChatListScreen
