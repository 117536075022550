import React from 'react'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const CompleteProfileModal = props => {
  const navigate = useNavigate()

  return (
    <Modal isCentered closeOnOverlayClick={false} isOpen={props.isOpen} onClose={props.onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Profilo non completato</ModalHeader>
        <ModalBody>
          <Text>Imposta una foto del profilo per poter organizzare eventi e partecipare ad essi</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={props.onClose}>
            Annulla
          </Button>
          <Button
            onClick={() => {
              navigate('/settings')
              props.onClose()
            }}>
            Completa profilo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CompleteProfileModal
