import type { Position, User } from 'typings/database'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AuthState } from './auth'
import { User as FirebaseUser } from 'firebase/auth'

const initialState: AuthState = {
  user: null,
  firebaseUser: null,
  position: null,
  isAuthenticated: false,
  isRegistered: false,
  isLoading: true,
  introSeen: false,
  telegramAlert: true,
  loadingPosition: false,
  skipPreferredCategories: false,
  previousUser: null,
  skipNumberConfirmation: false,
  canModifyPhoneNumber: false,
  savedUserCode: null,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setLoadingPosition(state, action: PayloadAction<boolean>) {
      state.loadingPosition = action.payload
    },
    setIntroSeen(state, action: PayloadAction<boolean>) {
      state.introSeen = action.payload
    },
    setTelegramAlert(state, action: PayloadAction<boolean>) {
      state.telegramAlert = action.payload
    },
    setSkipPreferredCategories(state, action: PayloadAction<boolean>) {
      state.skipPreferredCategories = action.payload
    },
    setSkipNumberConfirmation(state, action: PayloadAction<boolean>) {
      state.skipNumberConfirmation = action.payload
    },
    setCanModifyPhoneNumber(state, action: PayloadAction<boolean>) {
      state.canModifyPhoneNumber = action.payload
    },
    setPreviousUser(state, action: PayloadAction<User>) {
      state.previousUser = action.payload
    },
    setFirebaseUser(state, action: PayloadAction<FirebaseUser | null>) {
      state.firebaseUser = action.payload
    },
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload
    },
    setIsRegistered(state, action: PayloadAction<boolean>) {
      state.isRegistered = action.payload
    },
    setPosition(state, action: PayloadAction<Position | null>) {
      state.position = action.payload
    },
    setSavedUserCode(state, action: PayloadAction<string | null>) {
      state.savedUserCode = action.payload
    },
    clearPosition(state) {
      state.position = null
    },
  },
})

export const selectorAuth = (state: { auth: AuthState }) => state.auth
export const {
  setAuthState,
  setUser,
  setFirebaseUser,
  setPosition,
  clearPosition,
  setLoadingPosition,
  setIntroSeen,
  setLoading,
  setSkipPreferredCategories,
  setCanModifyPhoneNumber,
  setPreviousUser,
  setSkipNumberConfirmation,
  setTelegramAlert,
  setIsRegistered,
  setSavedUserCode,
} = auth.actions
export default auth.reducer
