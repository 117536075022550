import { useToast } from '@chakra-ui/react'
import { JSONValue } from '@twilio/conversations'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { Reply } from '../types'
import useGetConversationQueryBySid from './use-get-conversation-query'

const useSendTextMessageMutation = (options = {}) => {
  const { sid } = useParams()

  const { data } = useGetConversationQueryBySid(sid)
  const conversation = data?.conversation

  const toast = useToast()

  return useMutation(
    'send-message',
    async ({ message, reply }: { message: string; reply?: Reply }) => {
      if (!message) {
        return false
      }

      const attributes = reply ? ({ type: 'text', reply } as JSONValue) : ({ type: 'text' } as JSONValue)

      await conversation?.self.prepareMessage().setBody(message).setAttributes(attributes).build().send()
    },
    {
      onError(error: any) {
        console.error(error)

        toast({
          title: error?.message || 'Qualcosa è andato storto',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      },
      ...options,
    },
  )
}

export default useSendTextMessageMutation
