import { Flex, Text, Box, useToast, Button } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import Api from 'services/api/api.service'
import { Event } from 'typings/database.d'

import Wrapper from 'components/shared/wrapper/wrapper.component'
import EventPreview from 'components/shared/event-preview/event-preview.component'
import SearchOptions from 'components/shared/search-options/search-options.component'
import { selectorAuth } from 'features/auth/auth.slice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'features'
import { isInAppBrowser } from 'utils/is-in-app-browser'
import { setSearchToGlobal, selectorApp, setSearchToNear } from 'features/app/app.slice'
import { AdvBanner } from './adv-banner'
import { AttendeesIcon } from 'components/shared/icons/icons'

const initialSearch = {
  title: '',
  costMax: null,
  idCategory: [],
  dateFrom: '',
  dateTo: '',
  coordinates: undefined,
  location: { realPosition: { coordinates: undefined } },
}

const GLOBAL_SEARCH_RANGE_METERS = 25000000

const EventsSearchContent = ({ events }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { isSearchGlobal } = useSelector(selectorApp)

  if (!events.length && !isSearchGlobal) {
    return (
      <Box display="flex" flexDirection="column" alignContent="center">
        <Text align="center" mb="20px">
          Non ci sono eventi nelle tue vicinanze ma ecco 2 ottime idee
        </Text>
        <Box display="flex" flexDirection="row" alignContent="center" justifyContent="center" gap="2">
          <Button variant="ghost" onClick={() => dispatch(setSearchToGlobal())}>
            Mostra tutti
          </Button>
          <Button onClick={() => navigate('event/create')}>Creane uno</Button>
        </Box>
      </Box>
    )
  }

  if (!events.length && isSearchGlobal) {
    return (
      <Box>
        <Box pb="10px" fontSize="22px" fontWeight="700">
          Ops
        </Box>
        <Text mb="20px">Nessun evento nei paraggi.</Text>
        <Button onClick={() => navigate('event/create')}>Creane uno</Button>
      </Box>
    )
  }

  return (
    <>
      <Flex justify="space-around" px={8} textAlign="center" mt="10px" mb="10px">
        <Text fontSize="18px" cursor="pointer" onClick={() => dispatch(setSearchToNear())} opacity={!isSearchGlobal ? 1 : 0.5} fontWeight="semibold">
          Dintorni
        </Text>
        <Text fontSize="18px" cursor="pointer" onClick={() => dispatch(setSearchToGlobal())} opacity={isSearchGlobal ? 1 : 0.5} fontWeight="semibold">
          Ovunque
        </Text>
      </Flex>

      <Flex direction="column" gap=".8rem">
        {
          // position &&
          events.map((event: Event, index: number) => {
            return (
              <>
                <Link key={event._id} to={'event/' + event._id}>
                  <EventPreview {...event} />
                </Link>
                {/* Show banner every 3 */}
                {index > 0 && (index + 1) % 3 === 0 && <AdvBanner key={event._id + '_banner'} />}
              </>
            )
          })
        }
      </Flex>
    </>
  )
}

const EventsScreen = () => {
  const navigate = useNavigate()

  const toast = useToast()
  const [search, setSearch] = useState(initialSearch)
  const { isSearchGlobal } = useSelector(selectorApp)
  const { position } = useSelector(selectorAuth)
  const dateFrom = search.dateFrom ? search.dateFrom + 'T00:00:00.000Z' : undefined
  const dateTo = search.dateTo ? search.dateTo + 'T23:59:59.999Z' : undefined
  const lon = search.location?.realPosition?.coordinates?.[0] || position?.lon
  const lat = search.location?.realPosition?.coordinates?.[1] || position?.lat
  const searchParams = {
    idCategory: search.idCategory,
    costMax: search.costMax,
    lon,
    lat,
    title: search.title || undefined,
    dateFrom,
    dateTo,
    distanceRadiusMeters: isSearchGlobal ? GLOBAL_SEARCH_RANGE_METERS : undefined,
  }

  const { isLoading, data } = useQuery(['events', search, isSearchGlobal], () => Api.searchEvents(searchParams), {
    retry: false,
    onError: error => {
      if (typeof error == 'string') {
        toast({
          title: error,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
      if (typeof error?.message == 'string') {
        toast({
          title: error.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
    },
  }) as {
    error: any
    isLoading: boolean
    data: any
  }

  const events = data?.data || []

  const handleOnClickCommunities = () => {
    navigate({ pathname: '/chats/communityChat' })
  }

  if (isInAppBrowser()) {
    return (
      <Wrapper pt="20px" pb="90px">
        <Text py="30px">Apri l'applicazione dal browser del telefono o dall'app</Text>
      </Wrapper>
    )
  }

  if (isLoading) {
    return (
      <Wrapper pt="20px" pb="90px">
        <Text py="30px">Caricamento...</Text>
      </Wrapper>
    )
  }

  return (
    <Wrapper pt="20px" pb="90px">
      <Flex direction="column" mr="-5" ml="-5">
        <Box>
          {/* <Heading size="md">Eventi in programma</Heading> */}
          <SearchOptions {...{ search, setSearch }} />
        </Box>
      </Flex>
      <Flex flex={1} direction="column" gap={2}>
        <AdvBanner />
        <Button leftIcon={<AttendeesIcon />} fontSize="1.15rem" onClick={handleOnClickCommunities}>
          Entra nella community
        </Button>
        <EventsSearchContent events={events} />
      </Flex>
    </Wrapper>
  )
}

export default EventsScreen
