import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectorAuth } from '../features/auth/auth.slice'
import { useEffect, useState } from 'react'
import { isAndroid, isIos } from 'utils/platform'

export function useIsProfileComplete() {
  const { user } = useSelector(selectorAuth)
  const [isProfileComplete, setIsProfileComplete] = useState(false)

  useEffect(() => {
    setIsProfileComplete(user?.firstName && user?.lastName && user?.birthdate && user?.gender)
  }, [user])

  return isProfileComplete
}

export function useIsNumberConfirmed() {
  const { user, canModifyPhoneNumber, skipNumberConfirmation } = useSelector(selectorAuth)
  const [isNumberConfirmed, setIsNumberConfirmed] = useState(false)

  useEffect(() => {
    setIsNumberConfirmed(true /*(user?.phoneVerified && user?.phone && !canModifyPhoneNumber) || skipNumberConfirmation || isIos() || isAndroid()*/)
  }, [user])

  return isNumberConfirmed
}

export function useArePreferredCategoriesSet() {
  const { user, skipPreferredCategories } = useSelector(selectorAuth)
  const [arePreferredCategoriesSet, setArePreferredCategoriesSet] = useState(false)

  useEffect(() => {
    setArePreferredCategoriesSet(user?.preferredCategories?.length > 0 || skipPreferredCategories)
  }, [user, skipPreferredCategories])

  return arePreferredCategoriesSet
}

export function useProfileChecks() {
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuthenticated, isLoading, introSeen, isRegistered } = useSelector(selectorAuth)

  const isProfileComplete = useIsProfileComplete()
  const isNumberConfirmed = useIsNumberConfirmed()

  useEffect(() => {
    if (isLoading || !isAuthenticated) return
    let targetRoute: null | string = null

    if (!isRegistered) {
      targetRoute = '/signup'
    } else if (!isProfileComplete) {
      targetRoute = '/complete-profile'
    } else if (!isNumberConfirmed) {
      targetRoute = '/confirm-number'
    } else if (!introSeen) {
      targetRoute = '/welcome'
    }

    if (targetRoute && location.pathname !== targetRoute) {
      navigate(targetRoute, { state: { from: location.pathname } })
    }
  }, [isProfileComplete, isNumberConfirmed, introSeen, location.pathname, navigate, isAuthenticated, isLoading])
}
