import Api from 'services/api/api.service'
import LocalizationService from 'services/localization.service'
import { Button } from '@chakra-ui/button'
import { Spinner } from '@chakra-ui/spinner'
import { InputGroup, InputProps } from '@chakra-ui/input'
import { Box, Flex } from '@chakra-ui/layout'
import React, { Ref, useEffect, useRef, useState } from 'react'
import { PositionIcon } from '../icons/icons'
import { I, IRE, PositionI } from './input.style'
import { defaultLocation } from 'screens/create-event/create-event.screen'

export default React.forwardRef(function PositionInput(
  { value, onChange, placeholder, disabled = false }: InputProps & { value: any; onChange: any; disabled?: boolean },
  innerRef: Ref<any>,
) {
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState<false | string>(false)
  const throttle = useRef<any>()
  const search = async (search: string) => {
    clearTimeout(throttle.current)
    setQuery(search)
    setResults([])
    if (search !== '') {
      setLoading(true)
      throttle.current = setTimeout(async () => {
        const { data } = await Api.places(search)
        setResults(data)
        setLoading(false)
      }, 300)
    } else {
      setLoading(false)
      setPosition(defaultLocation)
    }
  }
  const geolocalize = async () => {
    setLoading(true)
    const {
      coords: { latitude, longitude },
    } = await LocalizationService.getCurrentPosition()
    const { data } = await Api.placesFromCoordinates(latitude, longitude)

    setResults(data)
    setLoading(false)
  }

  const setPosition = async (result: any) => {
    setLoading(true)
    setResults([])
    const { data } = await Api.place(result.placeId)
    setQuery(false)
    onChange({ placeId: result.placeId, ...data.location })
    setLoading(false)
  }
  return (
    <>
      <InputGroup size="sm">
        <PositionI
          disabled={disabled}
          cursor="pointer"
          focusBorderColor="#FF7436"
          variant="filled"
          value={query !== false ? query : value?.details?.address || ''}
          onChange={event => search(event.target.value)}
          placeholder={placeholder}
          type="text"
        />
        <IRE
          children={
            !disabled && (
              <Button right="-8px" borderRadius="10px" width="60px" height="100%" onClick={() => geolocalize()} mr="1">
                <PositionIcon boxSize="7" />
              </Button>
            )
          }
        />
      </InputGroup>
      <Box position="absolute" width="100%" background="white" zIndex="10" borderRadius="10px">
        {results.map((result: any) => (
          <Box
            key={result.description}
            p="5px 10px"
            border="2px solid #FF7436"
            borderRadius="10px"
            my="10px"
            cursor="pointer"
            onClick={() => setPosition(result)}>
            {result.description}
          </Box>
        ))}
        {loading && (
          <Flex p="5px 10px" border="2px solid #FF7436" alignItems="center" borderRadius="10px" my="10px">
            <Spinner size="sm" mr="10px" /> Caricamento risultati
          </Flex>
        )}
      </Box>
    </>
  )
})
