import React, { useContext, createContext } from 'react'
import useFormlib from './use-formlib'
import * as yup from 'yup'
import { ObjectSchema } from 'yup'
import { once } from 'lodash'
import { Formlib } from './use-formlib'

const createFormbitContext = once(<T extends {}>() => createContext<Formlib<T> | undefined>(undefined))

export default function FormbitContextProvider<T extends {}>({
  initialValues,
  schema,
  children,
}: {
  initialValues: T
  schema: ObjectSchema<T>
  children: React.ReactNode
}) {
  const FormbitContext = createFormbitContext<T>()

  const value = useFormlib(initialValues, schema || yup.object()) as Formlib<T>

  return <FormbitContext.Provider value={value}>{children}</FormbitContext.Provider>
}

export const useFormlibContext = <T extends {}>() => {
  const context = useContext(createFormbitContext<T>())

  if (!context) {
    // This error can only be thrown if this hook is called outside the context,
    // and it should only happen during develop.
    throw new Error('No FormbitContext.Provider found, useFormlibContext can only be called inside proper context')
  }

  return context
}
