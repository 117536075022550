import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import InputField from 'components/shared/input/input.component'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { useAppDispatch } from 'features'
import { loginAction, logoutAction } from 'features/auth/auth.actions'
import { selectorAuth, setIsRegistered, setSavedUserCode } from 'features/auth/auth.slice'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import useQueryParams from 'hooks/use-query-params'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createSearchParams } from 'react-router-dom'
import Api from 'services/api/api.service'
import { User } from 'typings/database.d'
import { validEmail } from 'utils/validate-email'
import useUTMTracking from 'hooks/use-utm-tracking'
import { getFirebaseAuth } from 'services/firebase.service'
import moment from 'moment'

const RegistrationScreen = () => {
  const toast = useToast()
  const authState = useSelector(selectorAuth)
  const queryParams = useQueryParams()
  const navigate = useNavigate()
  const queryStep = queryParams.get('step') ?? 1
  const step = typeof queryStep == 'number' ? queryStep : parseInt(queryStep)
  const email: string = queryParams.get('email') ?? ''
  const { savedUserCode } = useSelector(selectorAuth)
  const userCode = savedUserCode ? savedUserCode : queryParams.get('userCode') ?? ''
  const utm = useUTMTracking()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      userCode,
      email,
      lastName: '',
      firebaseUid: '',
      password: '',
      confirmPassword: '',
      hasTermsAndConditionAccepted: false,
      hasPrivacyPolicyAccepted: false,
      hasMarketingCommunicationsAccepted: false,
      birthdate: '',
    },
  })
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const navigationQuery = useMemo(
    () => ({
      step: '1',
      userCode,
    }),
    [userCode],
  )

  useEffect(() => {
    navigate(`/signup?${createSearchParams(navigationQuery)}`)
  }, [navigate, navigationQuery])

  useEffect(() => {
    if (savedUserCode === null && userCode !== '') {
      dispatch<any>(setSavedUserCode(userCode))
    }
  }, [])

  const onSubmit = async (data: User) => {
    setIsLoading(true)

    let user
    const auth = getFirebaseAuth()
    if (!auth.currentUser) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password)
        user = userCredential.user
      } catch (error: any) {
        if (error.code === 'auth/email-already-in-use') {
          setIsLoading(false)
          return toast({
            title: 'Email già registrata',
            status: 'error',
            duration: 2500,
            isClosable: true,
            position: 'top',
          })
        } else {
          setIsLoading(false)
          return toast({
            title: "Errore di sistema, contattare l'assistenza",
            status: 'error',
            duration: 2500,
            isClosable: true,
            position: 'top',
          })
        }
      }
    }

    const firebaseUid = user?.uid ?? auth.currentUser?.uid
    const email = user?.email ?? auth.currentUser?.email
    const accessToken = user?.accessToken ?? (await auth.currentUser?.getIdToken())

    try {
      const login = await Api.signUp({
        email,
        firebaseUid,
        hasTermsAndConditionAccepted: data.hasTermsAndConditionAccepted,
        hasPrivacyPolicyAccepted: data.hasPrivacyPolicyAccepted,
        hasMarketingCommunicationsAccepted: data.hasMarketingCommunicationsAccepted,
        userCode: data.userCode || undefined,
        birthdate: new Date(data.birthdate),
        ...utm,
      })

      if (login && login.status === 200) {
        dispatch<any>(setIsRegistered(true))
        await dispatch<any>(loginAction())
        if (auth.currentUser?.displayName) {
          const [firstName, ...lastName] = auth.currentUser.displayName.split(' ')
          await Api.editUser({
            firstName: firstName,
            lastName: lastName.join(' '),
          })
        }
      }
      dispatch<any>(setSavedUserCode(null))
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
      if (e?.response?.data?.message) {
        toast({
          title: e.response.data.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
      if (typeof e?.response?.data === 'string') {
        toast({
          title: e.response.data,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
    }
  }

  useEffect(() => {
    const auth = getFirebaseAuth()
    if (auth.currentUser && !authState.isRegistered && step === 1) {
      navigate({
        search: `?${createSearchParams({
          step: 2 + '',
        })}`,
      })
    } else if (authState.isRegistered && authState.user) {
      navigate('/')
    }
  }, [authState, navigate])

  const password = watch('password')

  const handleOnSubmit = (data: any) => {
    handleSubmit(handleNextStep)(data)
  }

  // Handle step with react router
  const handleNextStep = async data => {
    // if (step === 4 && !avatarUrl) return false
    if (step === 2) {
      return onSubmit(data)
    }
    navigate({
      search: `?${createSearchParams({
        step: step + 1 + '',
      })}`,
    })
  }

  const handleBack = () => {
    const auth = getFirebaseAuth()
    if (auth.currentUser) {
      dispatch<any>(logoutAction())
      navigate('/')
    } else {
      if (step === 1) return navigate('/')
      navigate({
        search: `?${createSearchParams({
          step: step - 1 + '',
        })}`,
      })
    }
  }

  return (
    <Wrapper pt="20px" pb="90px">
      <form style={{ display: 'contents' }} onSubmit={handleOnSubmit}>
        {step === 1 && (
          <>
            <Flex direction="row-reverse" padding="0">
              <CloseButton size="lg" onClick={handleBack} />
            </Flex>
            <Heading size="lg" mb={50} fontWeight="bold">
              Crea un account
            </Heading>
            <Box mb="30px">
              <FormControl isInvalid={!!errors.email}>
                <InputField
                  required
                  placeholder="Es. marcorossi@gmail.com"
                  label="Email"
                  {...register('email', {
                    required: 'Devi specificare una email',
                    validate: val => validEmail(val) || 'Devi specificare una mail valida',
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box mb="30px">
              <FormControl isInvalid={!!errors.password}>
                <InputField
                  label="Password"
                  placeholder="Una password sicura"
                  type="password"
                  required
                  {...register('password', {
                    required: 'Devi specificare una password',
                    minLength: { value: 6, message: 'La password deve avere almeno 6 caratteri' },
                  })}
                />
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                {!errors.password && !errors.confirmPassword && <FormHelperText>La password deve avere almeno 6 caratteri</FormHelperText>}
              </FormControl>
            </Box>
            <Box mb="30px">
              <FormControl isInvalid={!!errors.confirmPassword}>
                <InputField
                  label="Conferma password"
                  placeholder="Ripeti la password"
                  type="password"
                  required
                  {...register('confirmPassword', {
                    required: 'Devi confermare la password',
                    validate: value => value === password || 'Le password non coincidono',
                  })}
                />
                <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
              </FormControl>
            </Box>
            <Button isLoading={isLoading} width="90%" alignSelf="center" type="submit">
              Avanti
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <Button
              justifyContent="left"
              fontSize="md"
              onClick={handleBack}
              mb={50}
              fontWeight="bold"
              variant="link"
              leftIcon={<ArrowBackIcon boxSize={7} />}>
              Torna indietro
            </Button>
            <Heading mb={5} size="md" fontWeight="bold">
              Hai un codice?
            </Heading>
            <Box mb="30px">
              <FormControl>
                <InputField placeholder="Es. ABCDE6" type="string" {...register('userCode', { value: userCode })} />
                <FormHelperText>Inseriscilo quì. Riceverai saette bonus!</FormHelperText>
              </FormControl>
            </Box>
            <Heading mb={5} size="md" fontWeight="bold">
              Data di nascita
              <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                *
              </Text>
            </Heading>
            <Box mb="30px">
              <FormControl isInvalid={!!errors.birthdate}>
                <InputField
                  max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                  type="date"
                  label=""
                  placeholder=""
                  required
                  {...register('birthdate', {
                    required: 'Devi inserire la data di nascita',
                    validate: v => (!!v && moment().subtract(18, 'years').isSameOrAfter(v)) || 'Devi essere maggiorenne per unirti a Sbam!',
                  })}
                />
                <FormErrorMessage>{errors.birthdate?.message}</FormErrorMessage>
                {!errors.birthdate && <FormHelperText>Devi avere almeno 18 anni per unirti a Sbam!</FormHelperText>}
              </FormControl>
            </Box>
            <Heading size="md" mb={5} fontWeight="bold">
              Termini di utilizzo
            </Heading>
            <Box mb="30px">
              <FormControl isInvalid={!!errors.hasTermsAndConditionAccepted}>
                <Checkbox colorScheme="brand" {...register('hasTermsAndConditionAccepted', { required: 'Devi accettare i termini e condizioni' })}>
                  Accetto{' '}
                  <a style={{ textDecoration: 'underline' }} href="https://sbam.social/condizioni-di-utilizzo" target="_blank" rel="noreferrer">
                    termini e condizioni
                  </a>
                  <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                    *
                  </Text>
                </Checkbox>
                <FormErrorMessage>{errors.hasTermsAndConditionAccepted?.message}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box mb="30px">
              <FormControl isInvalid={!!errors.hasPrivacyPolicyAccepted}>
                <Checkbox colorScheme="brand" {...register('hasPrivacyPolicyAccepted', { required: 'Devi accettare la privacy policy' })}>
                  Accetto{' '}
                  <a style={{ textDecoration: 'underline' }} href="https://sbam.social/privacy-cookies-policy" target="_blank" rel="noreferrer">
                    privacy policy
                  </a>
                  <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                    *
                  </Text>
                </Checkbox>
                <FormErrorMessage>{errors.hasPrivacyPolicyAccepted?.message}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box mb="30px">
              <FormControl isInvalid={!!errors.hasMarketingCommunicationsAccepted}>
                <Checkbox colorScheme="brand" {...register('hasMarketingCommunicationsAccepted', { required: false })}>
                  Accetto di ricevere offerte e promozioni da parte di Sbam!
                </Checkbox>
                <FormErrorMessage>{errors.hasMarketingCommunicationsAccepted?.message}</FormErrorMessage>
              </FormControl>
            </Box>
            <Button isLoading={isLoading} width="90%" alignSelf="center" type="submit">
              Registrati
            </Button>
          </>
        )}
      </form>
    </Wrapper>
  )
}

export default RegistrationScreen
