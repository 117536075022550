import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Heading,
  PinInput,
  PinInputField,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react'
import InputField from 'components/shared/input/input.component'
import Wrapper from 'components/shared/wrapper/wrapper.component'
import { RecaptchaVerifier } from 'firebase/auth'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Api from 'services/api/api.service'
import { validPhone } from 'utils/validate-phone'

import { initializeApp } from 'firebase/app'
import { setSkipNumberConfirmation, setCanModifyPhoneNumber } from 'features/auth/auth.slice'
import { useIsNumberConfirmed } from 'hooks/use-profile-checks'
import { useAppDispatch } from 'features'
import { refreshUser } from 'features/auth/auth.actions'
import { getFirebaseAuth } from 'services/firebase.service'

const API_KEY = 'AIzaSyAUfWBJncTuBeMgKQL4iHSwNf62Aaa8XkI'
const AUTH_DOMAIN = 'sbam-prod.REACT_APP_firebaseapp.com'
const PROJECT_ID = 'sbam-prod'
const STORAGE_BUCKET = 'sbam-prod.appspot.com'
const MESSAGING_SENDER_ID = '908380287877'
const APP_ID = '1:908380287877:web:34cb5390f8c07311058aec'
const MEASUREMENT_ID = 'G-0RKEDXC6EB'

export const firebaseApp = initializeApp(
  {
    apiKey: API_KEY,
    authDomain: AUTH_DOMAIN,
    projectId: PROJECT_ID,
    storageBucket: STORAGE_BUCKET,
    messagingSenderId: MESSAGING_SENDER_ID,
    appId: APP_ID,
    measurementId: MEASUREMENT_ID,
  },
  'confirm-number',
)

const ConfirmNumberScreen = () => {
  const toast = useToast()
  const [codeSent, setCodeSent] = useState(false)
  const [canResend, setCanResend] = useState(false)
  const isNumberConfirmed = useIsNumberConfirmed()
  const [sessionInfo, setSessionInfo] = useState<string>()
  const dispatch = useAppDispatch()

  const smsTimer = useRef<any>()
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      phone: '+39',
      phoneConfirm: '',
    },
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isNumberConfirmed) {
      navigate('/')
    }
  }, [isNumberConfirmed, navigate])

  const handleSkip = () => {
    dispatch(setSkipNumberConfirmation(true))
    dispatch<any>(refreshUser())
  }

  const sendPhoneCode = async () => {
    const phone = '+' + getValues('phone')
    trigger('phone')
    if (!validPhone(phone)) return
    setIsLoading(true)
    try {
      const auth = getFirebaseAuth()
      if (!(window as any).verifier) {
        // // clear recaptcha container
        // const recaptchaContainer = document.getElementById('recaptcha-container')
        // if (recaptchaContainer) {
        //   recaptchaContainer.innerHTML = ''
        // }
        ;(window as any).verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'invisible',
        })
      }
      await (window as any).verifier.render()
      const recaptchaToken = await (window as any).verifier.verify()
      const result = await Api.sendPhoneCode({ phone, recaptchaToken })
      setSessionInfo(result.data.sessionInfo)
      setIsLoading(false)
      setCodeSent(true)
      setCanResend(false)
      smsTimer.current = setInterval(() => {
        setCanResend(true)
      }, 60000)
    } catch (error: any) {
      console.log(error)
      if (error.code === 'auth/invalid-phone-number') {
        return toast({
          title: 'Numero di telefono non valido',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      } else if (error.response && error.response.status === 400) {
        return toast({
          title: 'Troppi tentativi, riprova più tardi',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      } else {
        return toast({
          title: "Errore di sistema, contattare l'assistenza",
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
    }
    setIsLoading(false)
  }

  const onSubmit = async (data: any) => {
    const code = data.phoneConfirm
    try {
      setIsLoading(true)
      const result = await Api.verifyPhoneCode({ code, sessionInfo })
      if (result.data.success) {
        dispatch(setCanModifyPhoneNumber(false))
        dispatch<any>(refreshUser())
      }
      setIsLoading(false)
    } catch (error: any) {
      if (error.code === 'auth/invalid-verification-code') {
        return toast({
          title: 'Codice non valido',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      } else {
        return toast({
          title: "Errore di sistema, contattare l'assistenza",
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'top',
        })
      }
    }
  }

  const handleOnSubmit = (data: any) => {
    handleSubmit(onSubmit)(data)
  }

  const handleOnComplete = (data: any) => {
    handleOnSubmit({ phoneConfirm: data })
  }

  return (
    <Wrapper pt="20px" pb="90px" height="100%">
      <form style={{ display: 'contents' }} onSubmit={handleOnSubmit}>
        <Heading mt={5} size="md" mb={5} fontWeight="bold">
          Conferma il tuo numero di telefono
        </Heading>
        <Box mb="30px">
          <FormControl isInvalid={!!errors.phone}>
            <Controller
              control={control}
              rules={{
                validate: v => (!!v && validPhone(v)) || 'Devi inserire un numero di telefono valido',
              }}
              name="phone"
              render={({ field }) => <InputField placeholder="Numero di telefono" preferredCountries={['it']} type="tel" {...field} />}></Controller>
            <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <div id="recaptcha-container"></div>
        {!codeSent && (
          <Button isLoading={isLoading} width="90%" alignSelf="center" mb="30px" onClick={sendPhoneCode}>
            Richiedi codice
          </Button>
        )}
        {codeSent && (
          <>
            <Box mb="30px">
              <FormControl isInvalid={!!errors.phoneConfirm}>
                <Text pb="5px" fontWeight="semibold" color="#465362">
                  Codice di conferma
                  <Text as="span" color="brand.500" ml="4px" mt="-2px" fontSize="26px" position="absolute">
                    *
                  </Text>
                </Text>
                <HStack w="full">
                  <Controller
                    control={control}
                    rules={{
                      required: 'Devi inserire il codice di conferma',
                      validate: v => (!!v && v.length === 6) || 'Devi inserire un codice di conferma valido',
                    }}
                    name="phoneConfirm"
                    render={({ field }) => (
                      <PinInput otp onComplete={handleOnComplete} {...field}>
                        <PinInputField type="number" flex={1} />
                        <PinInputField type="number" flex={1} />
                        <PinInputField flex={1} />
                        <PinInputField flex={1} />
                        <PinInputField flex={1} />
                        <PinInputField flex={1} />
                      </PinInput>
                    )}></Controller>
                </HStack>
                <FormErrorMessage>{errors.phoneConfirm?.message}</FormErrorMessage>
                <Box mt={6} textAlign="center">
                  <FormHelperText>Puoi provare ogni 60 secondi</FormHelperText>
                  <Button size="sm" mt="3" px="30px" onClick={sendPhoneCode} disabled={!canResend}>
                    Reinvia codice
                  </Button>
                </Box>
              </FormControl>
            </Box>
            <Button isLoading={isLoading} width="90%" alignSelf="center" type="submit">
              Conferma numero
            </Button>
          </>
        )}
      </form>
      <Spacer />
      <Button isLoading={isLoading} height="50px" onClick={handleSkip}>
        Salta per ora
      </Button>
    </Wrapper>
  )
}

export default ConfirmNumberScreen
