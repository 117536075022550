import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const URL_REGEX_WITH_PROTOCOL = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g
const URL_REGEX_WITHOUT_PROTOCOL = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g
const TELEGRAM_GROUP_REGEX = /(?:https?:\/\/)?(?:www\.)?(t\.me|telegram\.me|telegram\.org)\/[a-zA-Z0-9_]+/g
const WHATSAPP_GROUP_REGEX = /(?:https?:\/\/)?chat\.whatsapp\.com\/[a-zA-Z0-9]+/g

function TextMessage({ msg: { body } }) {
  if (!body) {
    return <></>
  }

  return (
    <Box color="#465362" fontWeight="500" lineHeight="1.2rem">
      {body.split(' ').map(part => {
        if (TELEGRAM_GROUP_REGEX.test(part) || WHATSAPP_GROUP_REGEX.test(part)) {
          return null
        }

        if (URL_REGEX_WITH_PROTOCOL.test(part)) {
          return (
            <Text as="span" color="brand.500">
              <a href={part} style={{ textDecoration: 'underline' }} target="_blank" rel="noreferrer">
                {part}{' '}
              </a>
            </Text>
          )
        } else if (URL_REGEX_WITHOUT_PROTOCOL.test(part)) {
          return (
            <Text as="span" color="brand.500">
              <a href={'https://' + part} style={{ textDecoration: 'underline' }} target="_blank" rel="noreferrer">
                {part}{' '}
              </a>
            </Text>
          )
        } else {
          return part + ' '
        }
      })}
    </Box>
  )
}

export default TextMessage
