import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { FaCamera } from 'react-icons/fa'
import { useChatContext } from '../context/chat-context'
import useSendMediaMessageMutation from '../hooks/use-send-media-message-mutation'

function Photocamera() {
  const { setIsChatPlusMenuBoxVisible, reply, setReply, chatRef } = useChatContext()

  const sendMessage = useSendMediaMessageMutation({
    onSuccess() {
      setIsChatPlusMenuBoxVisible(false)
      setReply(undefined)

      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current?.scrollHeight
      }
    },
  })

  const handleOnSendFile = async () => {
    if (sendMessage.isLoading) {
      return
    }

    sendMessage.mutate(reply)
  }

  return (
    <Flex alignItems="center" gap=".75rem" onClick={handleOnSendFile} cursor="pointer">
      <Box color="brand.500" fontSize="1.5rem">
        <FaCamera />
      </Box>
      Foto
    </Flex>
  )
}

export default Photocamera
