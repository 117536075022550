import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'
import React from 'react'
import { LOCATION_PERMISSION_STATUS } from './chat-list-screen'
import { useNavigate } from 'react-router'

function EnableGpsModal({
  locationPermissionStatus,
  setLocationPermissionStatus,
}: {
  locationPermissionStatus: LOCATION_PERMISSION_STATUS
  setLocationPermissionStatus: (LOCATION_PERMISSION_STATUS) => void
}) {
  const navigate = useNavigate()
  const handleOnClose = () => {
    setLocationPermissionStatus('RUN_REQUEST_PERMISSION')
  }

  const handleOnGoToHomePage = () => {
    navigate('/')
  }

  const isOpen = locationPermissionStatus === 'GPS_OFF' || locationPermissionStatus === 'LOCATION_PERMISSION_DENIED'

  const handleOnClick = async () => {
    try {
      switch (locationPermissionStatus) {
        case 'GPS_OFF': {
          await NativeSettings.open({
            optionAndroid: AndroidSettings.Location,
            optionIOS: IOSSettings.LocationServices,
          })

          break
        }

        case 'LOCATION_PERMISSION_DENIED': {
          await NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
          })

          break
        }

        default: // DO NOTHING
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnGoToHomePage} isCentered>
      <ModalOverlay />
      <ModalContent width="85%">
        <ModalHeader />
        <ModalCloseButton size="lg" />
        <ModalBody>
          <Flex direction="column" gap="1rem">
            <Flex direction="column" gap=".25rem">
              <Text fontWeight="600">Dove ti trovi?</Text>
              <Text>Per mostrarti la chat della città a te più vicina, abbiamo bisogno dei permessi di localizzazione.</Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button alignSelf="center" variant="ghost" size="sm" width="100%" borderRadius="10px" onClick={handleOnClose}>
            Ho Fatto
          </Button>

          <Button alignSelf="center" size="sm" width="100%" borderRadius="10px" onClick={handleOnClick}>
            Attiva il GPS
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EnableGpsModal
