import {
  Box,
  Button,
  Checkbox,
  Flex,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { I } from 'components/shared/input/input.style'
import { FormlibContextProvider } from 'hooks/use-formlib'
import React, { ChangeEvent, useState } from 'react'
import { FaPollH } from 'react-icons/fa'
import * as yup from 'yup'
import { useChatContext } from '../context/chat-context'
import useSendPollMessageMutation from '../hooks/use-send-poll-mutation'
import { useFormlibContext } from 'hooks/use-formlib'

const schema = yup.object({
  question: yup.string().required('Aggiungi una domadna'),

  options: yup
    .array()
    .of(
      yup.object({
        value: yup.string(),
        votes: yup
          .array()
          .of(
            yup.object({
              identity: yup.string().required(),
              timestamp: yup.number().required(),
            }),
          )
          .required(),
        key: yup.string().required(),
      }),
    )
    .min(2)
    .max(5)
    .test((options = []) => {
      const nonEmptyOptionsValue = options.map(({ value }) => value).filter(Boolean).length
      const hasDuplicates = (function checkForDuplicates() {
        const array = options.map(({ value }) => value).filter(Boolean)
        return new Set(array).size !== array.length
      })()

      if (nonEmptyOptionsValue < 2) {
        return false
      }

      if (hasDuplicates) {
        return false
      }

      return true
    })
    .required(),

  multipleAnswers: yup.bool().required(),
})

type Schema = yup.InferType<typeof schema>
type FormOptions = Schema['options']

const initialValues: Schema = {
  question: '',
  options: [
    { value: undefined, votes: [], key: crypto.randomUUID().toString() },
    { value: undefined, votes: [], key: crypto.randomUUID().toString() },
  ],
  multipleAnswers: false,
}

function Poll() {
  return (
    <FormlibContextProvider<Schema> initialValues={initialValues} schema={schema}>
      <PollInner />
    </FormlibContextProvider>
  )
}

function PollInner() {
  const toast = useToast()
  const { reply, setIsChatPlusMenuBoxVisible, setReply, chatRef } = useChatContext()
  const sendPoll = useSendPollMessageMutation()

  const { onSubmit, form, isFormDirty } = useFormlibContext<Schema>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleOnClick = () => {
    setIsModalOpen(true)
  }

  const handleOnClose = () => {
    setIsModalOpen(false)
  }

  const handleOnSubmit = () => {
    onSubmit(
      async (form: Schema) => {
        try {
          const nonEmptyOptions = form.options.filter(o => !!o.value)
          const poll = { ...form, options: nonEmptyOptions }

          await sendPoll.mutate({ poll, reply })

          setIsModalOpen(false)
          setIsChatPlusMenuBoxVisible(false)
          setReply(undefined)

          if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current?.scrollHeight
          }
        } catch (e) {
          toast({
            title: 'Qualcosa è andato storto',
            status: 'error',
            duration: 2500,
            isClosable: true,
            position: 'bottom',
          })
        }
      },
      () => {
        toast({
          title: 'Imposta una domanda ed almeno due opzioni (no duplicati)',
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        })
      },
    )
  }

  return (
    <Flex alignItems="center" gap=".75rem" onClick={handleOnClick} cursor="pointer">
      <Box color="brand.500" fontSize="1.5rem">
        <FaPollH />
      </Box>

      <div>Sondaggio</div>

      <Modal isOpen={isModalOpen} onClose={handleOnClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody pb="40px">
            <Flex gap="2.5rem" direction="column">
              <Question />

              <Options options={form.options} />

              <MultipleAnswersCheckbox multipleAnswers={!!form.multipleAnswers} />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex color="brand.500" cursor="pointer" fontSize="1.5rem" width="100%" justifyContent="center" paddingBottom="3rem">
              <Button borderRadius="10px" isDisabled={!isFormDirty} isLoading={sendPoll.isLoading} onClick={handleOnSubmit} type="button">
                Crea Sondaggio
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

function Question() {
  const { write } = useFormlibContext<Schema>()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    write('question', e.target.value)
  }

  return (
    <Flex direction="column" gap="1rem" width="100%">
      <Box fontWeight="500">Domanda</Box>
      <I onChange={onChange} placeholder="Fai una domanda" />
    </Flex>
  )
}

function Options({ options }: { options: FormOptions }) {
  const { write } = useFormlibContext<Schema>()

  return (
    <Flex direction="column" gap="1rem" width="100%">
      <Box fontWeight="500">Opzioni</Box>

      {options.map((option, index) => {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
          if (index === options.length - 1 && !option[index] && options.length < 5) {
            options.push({ value: undefined, votes: [], key: crypto.randomUUID() })
          }

          write('options.[' + index + ']', { ...option, value: e.target.value })
        }

        const handleOnBlur = () => {
          const emptyValues = options.filter(o => !o.value)

          if (emptyValues.length <= 1) {
            return
          }

          if (options.length > 2 && !option.value) {
            options.splice(index, 1)
            write('options', options)
          }
        }

        return (
          <InputGroup key={option.key}>
            <I onBlur={handleOnBlur} onChange={onChange} value={option.value} placeholder="Scegli una opzione" />
          </InputGroup>
        )
      })}
    </Flex>
  )
}

function MultipleAnswersCheckbox({ multipleAnswers }: { multipleAnswers: boolean }) {
  const { write } = useFormlibContext<Schema>()

  const handleOnChangeMultipleOptions = ({ target: { checked } }) => {
    write('multipleAnswers', checked)
  }

  return (
    <Checkbox onChange={handleOnChangeMultipleOptions} colorScheme="brand.500" fontWeight="500" checked={multipleAnswers}>
      Consenti più risposte
    </Checkbox>
  )
}

export default Poll
