import { useCallback, useState } from 'react'
import set from 'lodash/set'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import { AnyObject, ObjectSchema, ValidationError } from 'yup'

export type Formlib<T> = {
  isFormDirty: boolean
  write: (string, unknown) => void
  remove: (string) => void
  onSubmit: (successCallback: (T, unknown) => void, errorCallback: () => void) => void
  form: T
  errors: Object
  setError: (path: keyof T, value: string) => void
}

const useFormlib = <T extends AnyObject>(initForm: T, schema: ObjectSchema<T>): Formlib<T> => {
  const [form, setForm] = useState<T>(initForm || {})
  const [errors, setErrors] = useState<Object>({})
  const [liveValidation, setLiveValidation] = useState<boolean>(false)
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)

  const setError = useCallback((path: keyof T, value: string): void => {
    setErrors(e => set(cloneDeep(e), path, value))
  }, [])

  const writeOrRemove = useCallback(
    (path: keyof T, value: any, action: string) => {
      const newForm = (function getNewForm() {
        switch (action) {
          case 'write':
            return set(cloneDeep(form), path, value)
          case 'remove':
            return omit(cloneDeep(form), path, value)
          default:
            return form
        }
      })() as T

      setForm(newForm)
      setIsFormDirty(true)

      if (liveValidation) {
        try {
          schema.validateSync(newForm, { abortEarly: false })
          setErrors({})
        } catch (error) {
          if (error instanceof ValidationError) {
            const newErrors = error.inner.reduce((acc, e) => ({ ...acc, [e.path || 'missing-path']: e.message }), {})

            setErrors(newErrors)
          }
        }
      }
    },
    [form, liveValidation, schema],
  )

  const write = useCallback(
    (path: keyof T, value: any) => {
      writeOrRemove(path, value, 'write')
    },
    [writeOrRemove],
  )

  const remove = useCallback(
    (path: keyof T) => {
      writeOrRemove(path, undefined, 'remove')
    },
    [writeOrRemove],
  )

  const onSubmit = useCallback(
    (successCallback: (T, unknown) => void, errorCallback: () => void) => {
      setLiveValidation(true)

      schema
        .validate(form, { abortEarly: false })
        .then(() => {
          setErrors({})
          setIsFormDirty(false)
          if (successCallback) {
            successCallback(form, setError)
          }
        })
        .catch(e => {
          console.error(e)
          const { inner } = e
          const newErrors = inner.reduce((acc, { message, path }) => ({ ...acc, [path]: message }), {})

          setErrors(newErrors)
          if (errorCallback) {
            errorCallback()
          }
        })
    },
    [form, schema, setError],
  )

  return {
    isFormDirty,
    write,
    remove,
    onSubmit,
    form,
    errors,
    setError,
  }
}

export default useFormlib
