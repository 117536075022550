import { border, createIcon, extendTheme } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import React from 'react'

export const customTheme = extendTheme({
  colors: {
    black: {
      500: '#011936',
    },
    brand: {
      500: '#FF7436',
      300: '#FF9D6E',
    },
  },
  components: {
    Accordion: {
      baseStyle: {
        button: {
          _hover: {
            bg: 'transparent',
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: '800',
      },
    },
    Badge: {
      variants: {
        AMBASSADOR: {
          bg: 'brand.500',
          color: 'white',
        },
        PARTNER: {
          bg: 'orange.300',
          color: 'white',
        },
        STAFF: {
          bg: 'red.500',
          color: 'white',
        },
        BOARD: {
          bg: '#011936',
          color: 'white',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '15px',
        fontWeight: '600',
      },
      variants: {
        ghost_border: {
          border: '1px solid',
          borderColor: 'gray',
          color: 'black',
        },
        outline: {
          border: '1px solid',
          borderColor: 'brand.500',
          color: 'brand.500',
        },
        outline_white: {
          border: '3px solid',
          borderColor: 'brand.500',
          bg: 'white',
          color: 'brand.500',
          _hover: {
            bg: '#e3e3e3',
          },
        },
        solid: {
          bg: 'brand.500',
          color: 'white',
          _hover: {
            bg: 'brand.300',
            color: 'white',
          },
          _visited: {
            bg: 'brand.300',
            color: 'white',
          },
          _focus: {
            bg: 'brand.300',
            color: 'white',
          },
          _active: {
            bg: 'brand.300',
            color: 'white',
          },
          _disabled: {
            _hover: {
              bg: 'brand.300',
              color: 'white',
            },
          },
        },
        solid_static: {
          bg: 'brand.500',
          color: 'white',
        },
        link: {
          color: 'black.500',
        },
      },
      sizes: {
        md: {
          fontSize: '20px',
          height: '47px',
          padding: '0 25px',
        },
      },
    },
    Input: {
      baseStyle: {
        backgroundColor: '#EEEEEE',
        borderRadius: '15px',
      },
      variants: {
        outline: {},
      },
      sizes: {
        md: {
          field: {
            fontSize: '14px',
            height: 'unset',
            padding: '15px 20px',
            borderRadius: '15px',
          },
        },
      },
    },
    PinInput: {
      variants: {
        outline: {
          gap: 'none',
          backgroundColor: '#EEEEEE',
          borderRadius: '15px',
          height: 'unset',
          padding: '25px 0',
          color: '#011936',
          border: '2px solid',
          borderColor: 'transparent',
          _hover: {
            borderColor: 'transparent',
          },
          _focus: {
            borderColor: ' #FF7436',
            outline: 'none',
            boxShadow: 'none',
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          padding: 3,
          _checked: {
            bg: 'brand.500',
          },
        },
        label: {
          color: '#011936',
          lineHeight: '1.2',
        },
      },
    },
    Textarea: {
      baseStyle: {
        borderRadius: '15px',
        borderWidth: '2px',
        shadows: {
          boxShadow: 'none',
        },
      },
      variants: {
        solid: {
          borderRadius: '15px',
          borderWidth: '2px',
          boxShadow: 'none',
          shadows: {
            boxShadow: 'none',
          },
        },
      },
      sizes: {
        md: {
          field: {
            fontSize: '14px',
            height: 'unset',
            padding: '15px 20px',
            borderRadius: '15px',
            borderWidth: '2px',
            shadows: {
              outline: 'none',
            },
          },
        },
      },
    },
    Text: {},
    Link: {
      baseStyle: {
        color: 'brand.500',
        textDecoration: 'underline',
        fontWeight: '600',
      },
    },
    Radio: {
      sizes: {
        lg: {
          control: { w: '6', h: '6' },
        },
      },
      variants: {
        whiteBorder: {
          label: {
            fontWeight: '550',
            fontSize: '20',
            color: '#737377',
            _checked: {
              color: '#FF7436',
            },
          },
        },
      },
    },
  },
})

export function CSSGlobal() {
  return (
    <Global
      styles={css`
        body {
          -webkit-user-select: none;
          user-select: none;

          font-family: 'Poppins', sans-serif;
          color: #011936;
          padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
        }
        html,
        body,
        #root {
          height: 100%;
        }
        a:active,
        a:focus {
          outline: none;
        }
        input,
        textarea,
        button,
        select,
        a {
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
      `}
    />
  )
}
