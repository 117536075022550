import { Box, CloseButton, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react'
import { default as React } from 'react'
import { useChatContext } from '../context/chat-context'
import { MessageAttributes } from '../types'
import EventMessageActions from './event-message-actions'
import MediaMessageActions from './media-message-actions'
import PollMessageActions from './poll-message-actions'
import PositionMessageActions from './position-message-actions'
import TextMessageActions from './text-message-actions'

function ChatDrawer() {
  const { messageForDrawer, setMessageForDrawer } = useChatContext()
  const attributes = messageForDrawer?.attributes as MessageAttributes

  const handleOnCloseDrawer = () => {
    setMessageForDrawer(undefined)
  }

  if (attributes?.deleted) {
    return <></>
  }

  return (
    <Drawer isOpen={!!messageForDrawer} placement="bottom" onClose={handleOnCloseDrawer}>
      <DrawerOverlay backgroundColor="rgba(0,0,0,0.1)" />

      <DrawerContent>
        <DrawerCloseButton>
          <Box color="brand.500">
            <CloseButton as="span" />
          </Box>
        </DrawerCloseButton>

        <DrawerHeader>
          <Box color="brand.500">Scegli una azione</Box>
        </DrawerHeader>

        <DrawerBody paddingBottom="2rem">
          <ChatDrawerBody />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

function ChatDrawerBody() {
  const { messageForDrawer } = useChatContext()

  if (!messageForDrawer) {
    return <></>
  }

  const type = (messageForDrawer?.attributes as { type: string })?.type

  switch (type?.toLowerCase()) {
    case 'text':
      return <TextMessageActions msg={messageForDrawer} />

    case 'media':
      return <MediaMessageActions msg={messageForDrawer} />

    case 'location':
      return <PositionMessageActions msg={messageForDrawer} />

    case 'event':
      return <EventMessageActions msg={messageForDrawer} />

    case 'poll':
      return <PollMessageActions msg={messageForDrawer} />

    default:
      return <TextMessageActions msg={messageForDrawer} /> // Retrocompatibility: {type: reply} and old text message without attributes, just message.body
  }
}

export default ChatDrawer
